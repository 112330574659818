import ArrowLeft from 'assets/icons/ic_24_arrow_left.svg?react';
import IconButton from 'components/clickables/IconButton';
import { JSX, RefObject } from 'react';
import styled from 'styled-components';
import { font, headerHeight, iconSize, spaceS, spaceXl, white } from 'styles/variables';

const Panel = styled.aside`
    z-index: 10;
    position: fixed;
    top: ${headerHeight};
    right: -600px; /* Initially hidden */
    width: 600px;
    height: 100%;
    background-color: ${white};
    overflow-y: auto;
    overscroll-behavior: contain;

    padding: ${spaceXl};
    transition: right 0.3s ease; /* Slide animation */

    &.open {
        right: 0; /* Slide in from the right */
        box-shadow: -4px 5px 10px 0px rgba(0, 0, 0, 0.1);
    }
`;

const Title = styled.section`
    display: flex;
    grid-template-columns: ${iconSize} 1fr;
    gap: ${spaceS};
    align-items: center;
    margin-bottom: ${spaceS};
    h2 {
        font-family: ${font.bold};
        font-size: ${font.size.xl};
        line-height: ${font.lineHeight.xl};
    }
`;

type SidePanelProps = {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children?: React.ReactNode;
    keepChildrenInDomWhenClosed?: boolean;
    ref?: RefObject<HTMLDivElement | null>;
};

function SidePanel({
    isOpen,
    onClose,
    title,
    children,
    keepChildrenInDomWhenClosed,
    ref,
}: SidePanelProps): JSX.Element {
    return (
        <Panel data-testid="sidePanel" className={isOpen ? 'open' : 'closed'} ref={ref}>
            <Title>
                <IconButton type="button" onClick={() => onClose()} data-testid="sidePanelBackButton">
                    <ArrowLeft />
                </IconButton>
                <h2>{title}</h2>
            </Title>
            {(!!keepChildrenInDomWhenClosed || isOpen) && children}
        </Panel>
    );
}

export default SidePanel;
