import { eachDayOfInterval, format, Locale } from 'date-fns';
import { de, enGB, nb, sv } from 'date-fns/locale';
import i18n from 'i18next';

type SupportedLanguages = 'en' | 'sv' | 'de' | 'nb';

export const getDateLocale = (): Locale => {
    const localeMapping: Record<SupportedLanguages, Locale> = {
        en: enGB,
        sv,
        de,
        nb,
    };
    const languageCode = i18n.language.split('-')[0] as SupportedLanguages;

    return localeMapping[languageCode] || enGB;
};

export const getLocalizedWeekdays = (style: 'short' | 'long'): Record<string, string> => {
    const dayFormat = style === 'short' ? 'EEEEEE' : 'EEEE';
    const monday = '2024-01-01';
    const daysOfWeek = eachDayOfInterval({
        start: new Date(monday),
        end: new Date(new Date(monday).setDate(new Date(monday).getDate() + 6)),
    });
    const weekObject: Record<string, string> = {};
    daysOfWeek.forEach((day) => {
        const keyName = format(day, 'EEEE', { locale: enGB }).toUpperCase();
        const keyValue = format(day, dayFormat, { locale: getDateLocale() });
        weekObject[keyName] = keyValue;
    });
    return weekObject;
};
