import BarChart from 'components/charts/BarChart';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import EmptyListMessage, { EMessageSize } from 'components/info/EmptyListMessage';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    borderColorGrey,
    borderRadiusM,
    colorHome,
    colorInternal,
    colorPublic,
    font,
    lighterBlue,
    panelBackgroundColorBlue,
    spaceM,
    spaceS,
    spaceXs,
} from 'styles/variables';
import { DynamicKpiData, EUsageType } from 'types/subscription';
import { generateBarChartData, getUsageByType } from 'utils/subscription/chart';

const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 3;
    min-height: 20.625rem;
    border-radius: ${borderRadiusM};
    border: 1px solid ${borderColorGrey};
    padding: 1.25rem;
    padding-bottom: ${spaceS};
    &.empty {
        background: ${panelBackgroundColorBlue};
        border-color: ${panelBackgroundColorBlue};
    }
`;
const ChartContanier = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 16.5rem auto;
    grid-template-rows: 1fr minmax(15.75rem, auto);

    @container kpi-container (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto 13rem;
    }
`;

const ChartInfo = styled.div`
    grid-row: span 2;
    margin-top: 0.75rem;
`;

const Title = styled.h2`
    font-size: ${font.size.l};
    margin-bottom: ${spaceS};
`;
const Total = styled.p`
    font-family: ${font.semiBold};
    margin-bottom: ${spaceM};
    display: flex;
    justify-content: space-between;
`;
const KwhItem = styled.p`
    min-height: 3rem;
    background: ${lighterBlue};
    border-radius: ${borderRadiusM};
    padding: 0 1.25rem 0 ${spaceS};
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: ${font.semiBold};
    margin-bottom: ${spaceXs};

    &.public {
        border-left: 0.5rem solid ${colorPublic};
    }
    &.private {
        border-left: 0.5rem solid ${colorInternal};
    }
    &.home {
        border-left: 0.5rem solid ${colorHome};
    }
`;

const Legend = styled.p`
    font-size: ${font.size.xs};
    margin-bottom: ${spaceS};
    margin-left: 3.375rem; //To align with the bar chart numbers

    @container kpi-container (max-width: 600px) {
        margin-top: ${spaceM};
    }
`;
type ConsumedEnergyChartProps = {
    data?: DynamicKpiData;
    timeFrame: string;
    isLoading: boolean;
};

function ConsumedEnergyChart({ data, timeFrame, isLoading }: ConsumedEnergyChartProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const locationData = data?.consumedEnergyForLocations ?? [];
    const publicKwh = getUsageByType(EUsageType.PUBLIC, locationData);
    const privateKwh = getUsageByType(EUsageType.INTERNAL, locationData);
    const homeKwh = getUsageByType(EUsageType.HOME, locationData);

    const total = formatNumber(publicKwh + privateKwh + homeKwh);

    const barChartData = data?.consumedEnergyChartEntries
        ? generateBarChartData(data.consumedEnergyChartEntries, timeFrame)
        : [];

    const barItemConfigData = [
        { dataKey: 'public', color: colorPublic, legend: t('usageType.PUBLIC') },
        { dataKey: 'internal', color: colorInternal, legend: t('usageType.INTERNAL') },
        { dataKey: 'home', color: colorHome, legend: t('usageType.HOME') },
    ];

    const noData = !isLoading && !barChartData.length;

    return (
        <Container className={noData ? 'empty' : ''}>
            {isLoading ? <LoadingIndicator displayText={false} height="100%" displayBorder={false} /> : null}
            {!isLoading && barChartData.length ? (
                <ChartContanier>
                    <ChartInfo>
                        <Title>{t('subscription.statistics.kpi.totalChargingPerLocation')}</Title>
                        <Total>
                            <span>{t('general.total')}</span>
                            <span>{total} kWh</span>
                        </Total>
                        <KwhItem className="public" data-testid="kwhUsagePublic">
                            <span>{t(`usageType.${EUsageType.PUBLIC}`)}</span>
                            <span>{formatNumber(publicKwh)} kWh</span>
                        </KwhItem>
                        <KwhItem className="private" data-testid="kwhUsageInternal">
                            <span>{t(`usageType.${EUsageType.INTERNAL}`)}</span>
                            <span>{formatNumber(privateKwh)} kWh</span>
                        </KwhItem>
                        <KwhItem className="home" data-testid="kwhUsageHome">
                            <span>{t(`usageType.${EUsageType.HOME}`)}</span>
                            <span>{formatNumber(homeKwh)} kWh</span>
                        </KwhItem>
                    </ChartInfo>
                    <Legend>kWh</Legend>
                    <BarChart xAxisLabel="kWh" barItemConfig={barItemConfigData} chartData={barChartData} />
                </ChartContanier>
            ) : null}
            {noData ? <EmptyListMessage title={t('subscription.statistics.noData')} size={EMessageSize.SMALL} /> : null}
        </Container>
    );
}

export default ConsumedEnergyChart;
