import { LoadingIndicator } from './LoadingIndicator';
import { JSX, memo } from 'react';
import styled from 'styled-components';
import { white } from 'styles/variables';

const FullPageContainer = styled.div`
    background-color: ${white};
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    > p {
        margin-top: 1em;
    }
`;

type FullPageLoadingIndicatorProps = {
    text?: string;
};

function FullPageLoadingIndicator(props: FullPageLoadingIndicatorProps): JSX.Element {
    const { text } = props;
    return (
        <FullPageContainer data-testid="fullPageLoadingIndicator">
            <LoadingIndicator loadingText={text} displayBorder={false} />
        </FullPageContainer>
    );
}

export default memo(FullPageLoadingIndicator);
