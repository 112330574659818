import InfoBox from './InfoBox';
import SupportIcon from 'assets/icons/ic_24_support.svg?react';
import useBUContent from 'hooks/useBUContent';
import { JSX } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceXxs } from 'styles/variables';
import { getLocalizedWeekdays } from 'utils/dateLocalization';

const ContactItem = styled.p`
    margin: 0;
    a {
        margin-left: ${spaceXxs};
        text-decoration: none;
        word-break: break-all;
    }
`;

function CustomerServiceInfoBox(): JSX.Element {
    const { t } = useTranslation();
    const { buContent } = useBUContent();
    const weekDays = getLocalizedWeekdays('long');
    const csOpeningHours = buContent.customerService.openingHours[0];
    const fromDay = csOpeningHours.dayFrom ? weekDays[csOpeningHours.dayFrom] : '';
    const toDay = csOpeningHours.dayTo ? weekDays[csOpeningHours.dayTo] : '';

    return (
        <InfoBox
            dataTestId="customerServiceInfoBox"
            color="blue"
            title={t('customerServiceInfoBox.title')}
            icon={<SupportIcon />}
            content={
                <>
                    <p>
                        <Trans
                            i18nKey="customerServiceInfoBox.openingHours"
                            values={{
                                dayFrom: fromDay,
                                dayTo: toDay,
                                fromHour: csOpeningHours.from,
                                toHour: csOpeningHours.to,
                            }}
                        />
                    </p>
                    <ContactItem>
                        {t('general.phone')}:
                        <a href={`tel:${buContent.customerService.phoneNumer}`}>
                            {buContent.customerService.phoneNumer}
                        </a>
                    </ContactItem>
                    <ContactItem>
                        {t('general.email')}:
                        <a href={`mailto:${buContent.customerService.email}`}>{buContent.customerService.email}</a>
                    </ContactItem>
                </>
            }
        />
    );
}

export default CustomerServiceInfoBox;
