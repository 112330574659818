import { JSX } from 'react';
import styled from 'styled-components';
import {
    borderColorGrey,
    borderRadiusM,
    font,
    maxWidthContent,
    screenWidthMini,
    spaceL,
    spaceM,
    spaceS,
} from 'styles/variables';

type MessageProps = {
    Illustration: React.ReactNode;
    title?: string;
    description?: string;
    tag?: keyof JSX.IntrinsicElements;
    children?: React.ReactNode;
    dataTestId?: string;
    displayBorder?: boolean;
    className?: string;
};

const MessageContainer = styled.section<{ $showBorder: boolean }>`
    border: 1px solid ${borderColorGrey};
    border-color: ${({ $showBorder }) => ($showBorder ? 'borderColorBlue' : 'transparent')};
    border-radius: ${borderRadiusM};
    padding: 8.563rem;
    padding-top: 7.5rem;
    max-width: ${maxWidthContent};

    @media screen and (max-width: ${screenWidthMini}) {
        padding: ${spaceL};
    }
`;

const MessageArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 25rem;
    margin: 0 auto;

    @media screen and (max-width: ${screenWidthMini}) {
        max-width: unset;
    }

    h1,
    h2,
    h3 {
        font-family: ${font.body};
        font-size: ${font.size.l};
        line-height: ${font.lineHeight.l};
        font-family: ${font.semiBold};
        text-align: center;
        margin-bottom: ${spaceS};
        margin-top: ${spaceM};
    }
`;

const Description = styled.p`
    font-size: ${font.size.m};
    text-align: center;
`;

function MessageBox({
    Illustration,
    title = '',
    description = '',
    tag = 'h1',
    children,
    dataTestId,
    displayBorder = true,
    className = '',
}: MessageProps): JSX.Element {
    const Heading = tag;
    return (
        <MessageContainer data-testid={dataTestId} $showBorder={displayBorder} className={className}>
            <MessageArea>
                {Illustration}
                {title && <Heading>{title}</Heading>}
                {description && <Description>{description}</Description>}
                {children}
            </MessageArea>
        </MessageContainer>
    );
}

export default MessageBox;
