import EmptyListMessage, { EMessageSize } from 'components/info/EmptyListMessage';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusM, font, lightBlue, panelBackgroundColorBlue, spaceM, spaceS } from 'styles/variables';
import { Charger } from 'types/subscription';

const Container = styled.section`
    grid-column: 1 / span 2;
    background: ${panelBackgroundColorBlue};
    border-radius: ${borderRadiusM};
    padding: ${spaceM};
    h2 {
        font-size: ${font.size.l};
        margin-bottom: ${spaceS};
    }
    ol {
        list-style: none;
    }
    p {
        margin: 0;
    }
`;

const ListItem = styled.li`
    border-bottom: 1px solid ${lightBlue};
    padding: 0.75rem 0;
    display: flex;
    justify-content: space-between;
    gap: ${spaceM};
    align-items: center;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
`;

const Text = styled.p`
    span {
        display: block;
        &:last-child {
            font-size: ${font.size.s};
        }
    }
`;
const Kwh = styled.p`
    align-self: start;
    font-family: ${font.semiBold};
`;

type MostUsedChargersProps = {
    chargerPoints?: Charger[];
};

function MostUsedChargers({ chargerPoints = [] }: MostUsedChargersProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    return (
        <Container>
            <h2>{t('subscription.statistics.kpi.chargers.title')}</h2>
            {chargerPoints.length === 0 ? (
                <EmptyListMessage size={EMessageSize.SMALL} />
            ) : (
                <ol data-testid="mostUsedChargers">
                    {chargerPoints.map((item) => {
                        return (
                            <ListItem key={item.name}>
                                <Text>
                                    <span>{item.name}</span>
                                    <span>
                                        {item.address.addressLine1}, {item.address.postalCode} {item.address.city}
                                    </span>
                                </Text>
                                <Kwh>{formatNumber(item.consumedEnergy)} kWh</Kwh>
                            </ListItem>
                        );
                    })}
                </ol>
            )}
        </Container>
    );
}

export default MostUsedChargers;
