import ErrorMessage from 'components/info/ErrorMessage';
import PageLayoutWithoutMenu from 'components/layout/PageLayoutWithoutMenu';
import { JSX } from 'react';
import { EPageErrorType } from 'types';

function ErrorPageWithoutUserMenu(): JSX.Element {
    return (
        <PageLayoutWithoutMenu headerWithUserMenu={false}>
            <ErrorMessage type={EPageErrorType.TEMPORARY} />
        </PageLayoutWithoutMenu>
    );
}

export default ErrorPageWithoutUserMenu;
