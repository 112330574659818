import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import PopUp from 'components/info/PopUp';
import TooltipContentComponent from 'components/info/TooltipContent';
import { Fragment, JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, spaceXs } from 'styles/variables';
import { ETrackingOrigin, ETrackingType } from 'types/tracking';

const Container = styled.dl`
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: ${spaceXs};
    dd {
        font-family: ${font.bold};
    }
`;

type Status = {
    title: string;
    text: string;
};

type StatusInfoPopupProps = {
    statusList: Status[];
    gaTrackingOrigin: ETrackingOrigin;
};

function StatusInfoPopup({ statusList, gaTrackingOrigin }: StatusInfoPopupProps): JSX.Element {
    const { t } = useTranslation();
    return (
        <PopUp
            dataTestId="statusInfoButton"
            gaTracking={{
                type: ETrackingType.STATUS_INFO,
                origin: gaTrackingOrigin,
            }}
            content={
                <TooltipContentComponent
                    headline={t('widget.statusInfoPopup.title')}
                    content={
                        <Container data-testid="statusInfoList">
                            {statusList.map((status) => (
                                <Fragment key={status.title}>
                                    <dd>{status.title}:</dd>
                                    <dt>{status.text}</dt>
                                </Fragment>
                            ))}
                        </Container>
                    }
                    icon={<QuestionMarkTooltipIcon />}
                />
            }
        />
    );
}

export default StatusInfoPopup;
