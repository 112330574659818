import { LoadingIndicator } from '../components/indicators/LoadingIndicator';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ErrorMessage from 'components/info/ErrorMessage';
import useAuthentication from 'hooks/useAuthentication';
import React, { JSX, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { EPageErrorType } from 'types';

const Container = styled.section`
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IS_LOADING_CLASS = 'is-loading';
const IS_ERROR_CLASS = 'is-error';

function SignUpPage(): JSX.Element {
    const { signupRedirect } = useAuthentication();
    const { inProgress } = useMsal();
    const [searchParams] = useSearchParams();
    const emailQP = searchParams.get('email') || '';

    useEffect(() => {
        document.documentElement.classList.add(IS_LOADING_CLASS);
        return () => {
            document.documentElement.classList.remove(IS_LOADING_CLASS);
        };
    }, []);

    useEffect(() => {
        if (!emailQP && inProgress === InteractionStatus.None) {
            document.documentElement.classList.remove(IS_LOADING_CLASS);
            document.documentElement.classList.add(IS_ERROR_CLASS);
        }
        if (emailQP && inProgress === InteractionStatus.None) {
            signupRedirect(emailQP);
        }
        return () => {
            document.documentElement.classList.remove(IS_ERROR_CLASS);
        };
    }, [signupRedirect, inProgress, emailQP]);

    return !emailQP && inProgress === InteractionStatus.None ? (
        <ErrorMessage type={EPageErrorType.BROKEN_INVITATION_URL} />
    ) : (
        <Container>
            <LoadingIndicator displayBorder={false} />
        </Container>
    );
}

export default SignUpPage;
