import useAuthentication from './useAuthentication';
import { useQuery } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { db } from 'firebase/firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { UserPreferences } from 'types/userPreferences';

type useUserPreferencesReturnProps = {
    userPreferences?: UserPreferences;
    isLoading: boolean;
    error?: ApiError | null;
    setUserPreferences: (data: UserPreferences) => Promise<void>;
};

const useUserPreferences = (isDisabled = false): useUserPreferencesReturnProps => {
    const { getAccountContactId } = useAuthentication();
    const contactId = getAccountContactId();
    const userQueryKey = 'firebaseUserPreferences';
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchDocument = async (): Promise<UserPreferences> => {
        const docRef = doc(db, 'users', contactId);
        const docSnap = await getDoc(docRef);

        if (docSnap.data()) {
            setIsLoading(false);
            return docSnap.data() as UserPreferences;
        }

        setIsLoading(false);
        return {};
    };

    const { data: userPreferences, error } = useQuery({
        queryKey: [userQueryKey],
        queryFn: fetchDocument,
        enabled: !!contactId && !isDisabled,
        ...REACT_QUERY_ONE_CALL_OPTIONS,
        initialData: undefined,
    });

    const setUserPreferences = async (data: UserPreferences): Promise<void> => {
        await setDoc(doc(db, 'users', contactId), data, { merge: true });
    };

    return {
        userPreferences,
        isLoading,
        error,
        setUserPreferences,
    };
};

export default useUserPreferences;
