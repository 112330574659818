import { JSX } from 'react';
import styled from 'styled-components';
import { borderRadiusM, font, lighterBlue, lighterGrey, spaceM, spaceXs } from 'styles/variables';

const Box = styled.article`
    padding: ${spaceM};
    padding-left: 1.25rem;
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 0.75rem;
    border-radius: ${borderRadiusM};
    word-break: break-word;
    h2 {
        font-size: ${font.size.l};
        font-family: ${font.bold};
        margin-top: 6px;
        margin-bottom: ${spaceXs};
        line-height: ${font.lineHeight.l};
    }
    &.grey {
        background: ${lighterGrey};
    }
    &.blue {
        background: ${lighterBlue};
    }
`;

type Props = {
    icon: JSX.Element;
    title: string;
    content: JSX.Element;
    color: 'grey' | 'blue';
    dataTestId?: string;
};

function InfoBox({ icon, title, content, color, dataTestId = 'infoBox' }: Props): JSX.Element {
    return (
        <Box className={color} data-testid={dataTestId}>
            {icon}
            <div>
                <h2>{title}</h2>
                {content}
            </div>
        </Box>
    );
}

export default InfoBox;
