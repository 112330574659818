import Button from 'components/clickables/Button';
import FilterSection from 'components/elements/FilterSection';
import { ModalTitle } from 'components/elements/ModalTitle';
import RadioButton from 'components/forms/RadioButton';
import { useModal } from 'contexts/Modal';
import { ChangeEvent, JSX, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceM, spaceS } from 'styles/variables';
import { ButtonVariant } from 'types';
import { ESubscriptionStatus } from 'types/subscription';
import { ETrackingEvent, ETrackingOrigin, ETrackingType } from 'types/tracking';

const Container = styled.div`
    min-height: 25rem;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled.div`
    margin: ${spaceM};
    margin-top: auto;
    display: flex;

    gap: ${spaceS};
    button {
        flex: 1;
    }
`;

type Props = {
    onFilter: (data: { status: string }) => void;
    filterData: { status: string };
};

function SubscriptionOverviewFilter({ onFilter, filterData }: Props): JSX.Element {
    const { t } = useTranslation();
    const { close } = useModal();

    const [filterValues, setFilterValues] = useState(filterData);

    const handleApplyFilter = (): void => {
        onFilter(filterValues);
        close();
        ReactGA.event(ETrackingEvent.APPLY_FILTER, {
            origin: ETrackingOrigin.SUBSCRIPTION,
            type: ETrackingType.STATUS_FILTER,
        });
    };

    const clearFilter = (): void => {
        setFilterValues({ status: '' });
    };

    const onStausChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilterValues({ status: e.currentTarget.value });
    };

    return (
        <Container data-testid="subscriptionOverviewFilterModal">
            <ModalTitle>{t('filterModal.title')}</ModalTitle>
            <FilterSection title={t('filterModal.status')} dataTestId="filterSectionStatus">
                <RadioButton
                    dataTestId="status_all"
                    id="all"
                    name="statusOptions"
                    value=""
                    onChange={onStausChange}
                    checked={filterValues.status === ''}
                    label={t('general.all')}
                />
                <RadioButton
                    dataTestId="status_active"
                    id="active"
                    name="statusOptions"
                    value={ESubscriptionStatus.ACTIVE}
                    onChange={onStausChange}
                    checked={filterValues.status === ESubscriptionStatus.ACTIVE}
                    label={t('status.active')}
                />
                <RadioButton
                    dataTestId="status_inactive"
                    id="inactive"
                    name="statusOptions"
                    value={ESubscriptionStatus.INACTIVE}
                    onChange={onStausChange}
                    checked={filterValues.status === ESubscriptionStatus.INACTIVE}
                    label={t('status.inactive')}
                />
            </FilterSection>
            <ButtonContainer>
                <Button variant={ButtonVariant.SECONDARY} onClick={clearFilter} dataTestId="clearFilterButton">
                    {t('filterModal.clear')}
                </Button>
                <Button dataTestId="applyFilterButton" onClick={handleApplyFilter}>
                    {t('filterModal.apply')}
                </Button>
            </ButtonContainer>
        </Container>
    );
}

export default SubscriptionOverviewFilter;
