import ArrowLeft from 'assets/icons/arrow_left.svg?react';
import ArrowRight from 'assets/icons/arrow_right.svg?react';
import DoubleArrowLeft from 'assets/icons/double_arrow_left.svg?react';
import DoubleArrowRight from 'assets/icons/double_arrow_right.svg?react';
import { JSX, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    black,
    borderColorGrey,
    borderRadiusM,
    darkGrey,
    font,
    lighterBlue,
    lighterGrey,
    mediumGrey,
    primaryMerBlue,
} from 'styles/variables';
import { pagesToDisplay } from 'utils/pagination';

const Pagination = styled.section`
    display: flex;
    width: 100%;
    justify-content: center;
`;
const PaginationButton = styled.button`
    font-family: ${font.body};
    border-left: 1px solid ${borderColorGrey};
    border-right: none;
    border-bottom: 1px solid ${borderColorGrey};
    border-top: 1px solid ${borderColorGrey};
    min-width: 32px;
    height: 32px;
    background: transparent;

    cursor: pointer;
    line-height: 0;
    color: ${black};
    font-family: ${font.semiBold};
    font-size: ${font.size.xs};

    :hover:not(:disabled) {
        :not(.active-page-button) {
            background: ${lighterGrey};
        }
    }

    svg path {
        fill: ${darkGrey};
    }

    &:disabled {
        svg path {
            fill: ${mediumGrey};
        }
    }
    &.active-page-button {
        background: ${primaryMerBlue};
        border: none;
        color: white;
    }

    &:not(.active-page-button) {
        &:hover {
            background-color: ${lighterBlue};
        }
    }

    &:first-child {
        border-left: 1px solid ${borderColorGrey};
        border-radius: ${borderRadiusM} 0 0 ${borderRadiusM};
    }
    &:last-child {
        border-right: 1px solid ${borderColorGrey};
        border-radius: 0 ${borderRadiusM} ${borderRadiusM} 0;
    }
`;

type PaginationBarProps = {
    pageNumber: number;
    totalNumberOfPages: number;
    isLastPage: boolean;
    onChangePage: (page: number) => void;
};

function PaginationBar({
    pageNumber,
    totalNumberOfPages,
    isLastPage = false,
    onChangePage,
}: PaginationBarProps): JSX.Element | null {
    const { t } = useTranslation();
    const pageList = useMemo(() => {
        return pagesToDisplay({ currentPage: pageNumber, totalPages: totalNumberOfPages });
    }, [pageNumber, totalNumberOfPages]);

    if (totalNumberOfPages <= 1) return null;

    return (
        <Pagination data-testid="paginationBar">
            <PaginationButton
                type="button"
                disabled={pageNumber === 1}
                onClick={() => {
                    onChangePage(1);
                }}
                aria-label={t('pagination.firstPage')}
            >
                <DoubleArrowLeft />
            </PaginationButton>
            <PaginationButton
                type="button"
                disabled={pageNumber === 1}
                onClick={() => {
                    onChangePage(pageNumber - 1);
                }}
                aria-label={t('pagination.previousPage')}
            >
                <ArrowLeft />
            </PaginationButton>
            {pageList.map((page: number) => (
                <PaginationButton
                    key={`page-button-${page}`}
                    className={page === pageNumber ? 'active-page-button' : ''}
                    type="button"
                    disabled={page === pageNumber}
                    onClick={() => {
                        onChangePage(page);
                    }}
                >
                    {page}
                </PaginationButton>
            ))}

            <PaginationButton
                type="button"
                disabled={isLastPage}
                onClick={() => {
                    onChangePage(pageNumber + 1);
                }}
                aria-label={t('pagination.nextPage')}
            >
                <ArrowRight />
            </PaginationButton>
            <PaginationButton
                type="button"
                disabled={isLastPage}
                onClick={() => {
                    onChangePage(totalNumberOfPages);
                }}
                aria-label={t('pagination.lastPage')}
            >
                <DoubleArrowRight />
            </PaginationButton>
        </Pagination>
    );
}

export default PaginationBar;
