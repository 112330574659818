import StatusBox from './StatusBox';
import { JSX } from 'react';
import styled from 'styled-components';
import { spaceM } from 'styles/variables';
import { EConnectorStatus, IConnectorStatus, IProperty } from 'types/facility';
import { getAmountPerType, getFilteredConnectors } from 'utils/connectorStatus';

const StatusBoxContainer = styled.section`
    display: flex;
    gap: 1rem;
    margin-bottom: ${spaceM};
    flex-wrap: wrap;
`;

type StatusWidgetProps = {
    properties: IProperty[];
    connectorState: IConnectorStatus[];
};

function StatusWidget({ properties, connectorState }: StatusWidgetProps): JSX.Element {
    if (properties.length < 1) {
        return <div data-testid="emptyStatusWidget" />;
    }

    const totalNumbersOfConnectors = getFilteredConnectors(properties).length;
    const listOfConnectorStatuses = Object.keys(EConnectorStatus);

    return (
        <StatusBoxContainer>
            {listOfConnectorStatuses.map((key) => {
                const connectorStatusValue = key as EConnectorStatus;

                return (
                    <StatusBox
                        totalNumbersOfConnectors={totalNumbersOfConnectors}
                        key={connectorStatusValue}
                        status={connectorStatusValue}
                        amount={getAmountPerType(properties, connectorState, connectorStatusValue)}
                    />
                );
            })}
        </StatusBoxContainer>
    );
}

export default StatusWidget;
