import DropdownInput from 'components/forms/DropdownInput';
import Page from 'components/layout/Page';
import ActiveSubscriptionsKpi from 'components/subscriptions/statistics/ActiveSubscriptionsKpi';
import Co2Kpi from 'components/subscriptions/statistics/Co2Kpi';
import DynamicKPISection from 'components/subscriptions/statistics/DynamicKPISection';
import InvoiceInfo from 'components/subscriptions/statistics/InvoiceInfo';
import { pageKeys } from 'constants/pageKeys';
import SubscriptionStatisticsContext from 'contexts/SubscriptionStatisticsContext';
import useBusinesses from 'hooks/useBusinesses';
import useGetBusiness from 'hooks/useGetBusiness';
import { createContext, JSX } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceM } from 'styles/variables';
import { EBusinessFeature } from 'types/business';
import { ETrackingOrigin } from 'types/tracking';

const StyledDropdownInput = styled(DropdownInput)`
    max-width: 18.75rem;
`;

const KPIPageWrapper = styled.div`
    container-type: inline-size;
    container-name: kpi-container;
`;
const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${spaceM};

    @container kpi-container (max-width: 600px) {
        display: flex;
        flex-direction: column;
    }
`;

const KpiContainer = styled.section`
    grid-column: span 3;
    display: flex;
    gap: ${spaceM};
    flex-wrap: wrap;

    > * {
        flex: 1;
        min-width: 12.5rem;
        max-width: 24rem;

        @container kpi-container (max-width: 550px) {
            min-width: 100%;
        }
    }
`;

export const DropdownContext = createContext('organisationDropdownValue');

function SubscriptionStatistics(): JSX.Element {
    const { t } = useTranslation();

    const { businesses } = useBusinesses();
    const { getBusinessesListByFeature } = useGetBusiness(businesses);

    const organisationList = getBusinessesListByFeature(
        [EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT, EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW],
        true,
    );

    const selectedOrganisation = organisationList[0].value;

    const { register, watch } = useForm({ defaultValues: { organisationDropdown: selectedOrganisation } });

    const selectedDropdownValue = watch('organisationDropdown');

    const OrganisationsDropDown =
        organisationList.length > 1 ? (
            <StyledDropdownInput
                dataTestId="organisationDropdown"
                options={organisationList.map((business) => ({
                    value: business.value,
                    text: business.text,
                }))}
                {...register('organisationDropdown')}
                name="organisationDropdown"
                defaultValue={selectedOrganisation}
                hidePlaceholderOption
                hideOptionalText
            />
        ) : undefined;

    return (
        <Page
            pageKey={pageKeys.SUBSCRIPTION_STATISTICS}
            title={t('subscription.statistics.title')}
            description={<InvoiceInfo origin={ETrackingOrigin.SUBSCRIPTION_STATISTICS} />}
        >
            <KPIPageWrapper>
                {OrganisationsDropDown}
                <Container>
                    <SubscriptionStatisticsContext value={selectedDropdownValue}>
                        <KpiContainer>
                            <ActiveSubscriptionsKpi />
                            <Co2Kpi />
                        </KpiContainer>
                        <DynamicKPISection />
                    </SubscriptionStatisticsContext>
                </Container>
            </KPIPageWrapper>
        </Page>
    );
}

export default SubscriptionStatistics;
