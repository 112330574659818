import styled from 'styled-components';
import { spaceM, spaceS } from 'styles/variables';

export const UtilityBar = styled.div`
    display: flex;
    gap: ${spaceS};
    margin-bottom: ${spaceS};
`;

export const UtilityBarWrapper = styled.div<{ $hasSelectedFilters: boolean }>`
    margin-bottom: ${({ $hasSelectedFilters }) => ($hasSelectedFilters ? spaceS : spaceM)};
`;
