import CloseIcon from 'assets/icons/close.svg?react';
import IconButton from 'components/clickables/IconButton';
import { useModal } from 'contexts/Modal';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import { borderRadiusM, colorModalOverlay, screenWidthSmall, spaceL, spaceM, spaceS, white } from 'styles/variables';

const CustomReactModal = styled(ReactModal)`
    position: relative;
    background-color: ${white};
    outline: none;
    z-index: 100;
    max-width: 90%;
    margin: ${spaceL} auto;
    box-shadow: 0 5px 10px 5px rgba(107, 107, 107, 0.5);
    border-radius: ${borderRadiusM};
    @media screen and (min-width: ${screenWidthSmall}) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        max-height: 95vh;
        overflow-y: auto;
    }
`;

const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: ${spaceS};
    right: ${spaceM};
`;

function ModalWrapper(): JSX.Element {
    ReactModal.setAppElement('body');
    const handleModal = useModal();
    const { t } = useTranslation();

    return (
        <CustomReactModal
            style={{
                overlay: {
                    backgroundColor: colorModalOverlay,
                },
                content: {
                    width: handleModal.width,
                    minHeight: handleModal.height,
                },
            }}
            isOpen={handleModal.isOpen}
            shouldCloseOnOverlayClick={handleModal.shouldCloseOnOverlayClick}
            shouldCloseOnEsc={handleModal.shouldCloseOnEsc}
            shouldReturnFocusAfterClose
            onRequestClose={() => {
                handleModal.close();
            }}
        >
            {handleModal.showCloseButton ? (
                <StyledIconButton
                    onClick={() => handleModal.close()}
                    title={t('general.close')}
                    disabled={handleModal.isLocked}
                    type="button"
                >
                    <CloseIcon />
                </StyledIconButton>
            ) : null}

            {handleModal.newModalContent}
        </CustomReactModal>
    );
}

export default ModalWrapper;
