import { JSX } from 'react';
import styled from 'styled-components';
import { borderRadiusM, contentBoxPadding, font, lighterGrey, spaceS, spaceXs } from 'styles/variables';

const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(12rem, 1fr) minmax(10rem, 1fr);
    flex: 1;
    min-height: 12.5rem;
    background-color: ${lighterGrey};
    border-radius: ${borderRadiusM};
`;

const TextBox = styled.div`
    padding: ${contentBoxPadding};
`;

const Title = styled.h3`
    font-size: ${font.size.subtitle};
    line-height: ${font.lineHeight.l};
    margin-bottom: ${spaceXs};
`;

const Text = styled.p`
    color: ${font.color.grey};
    line-height: ${font.lineHeight.l};
    margin-bottom: ${spaceS};
`;

const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0 ${borderRadiusM} ${borderRadiusM} 0;
`;

export type SmallContentBoxProps = {
    image: string;
    title: string;
    text: string | JSX.Element | JSX.Element[];
    children?: React.ReactNode;
    dataTestId?: string;
};

function SmallContentBox(props: SmallContentBoxProps): JSX.Element {
    const { image, title, text, children, dataTestId } = props;

    return (
        <Container data-testid={dataTestId}>
            <TextBox>
                <Title>{title}</Title>
                <Text>{text}</Text>
                {children}
            </TextBox>
            <Image src={image} />
        </Container>
    );
}

export default SmallContentBox;
