import { JSX } from 'react';
import styled from 'styled-components';
import { font, spaceS } from 'styles/variables';

const Container = styled.div`
    display: flex;
    gap: ${spaceS};
    align-items: center;
    p {
        margin: 0;
        font-size: ${font.size.xl};
        font-family: ${font.bold};
        line-height: ${font.lineHeight.xl};
    }
`;

const IconContainer = styled.div`
    height: 2.313rem; //37px
    svg {
        height: 100%;
    }
`;
type KpiContentProps = {
    icon: React.ReactNode;
    text: string | React.ReactElement;
};

function KpiContent({ icon, text }: KpiContentProps): JSX.Element {
    return (
        <Container>
            <IconContainer>{icon}</IconContainer>
            <p>{text}</p>
        </Container>
    );
}

export default KpiContent;
