import ToastMessage from './ToastMessage';
import { useToast } from 'contexts/Toast';
import { JSX } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { headerZIndex } from 'styles/variables';

const Container = styled.div`
    position: fixed;
    right: 0;
    top: 0;
    z-index: calc(${headerZIndex} + 1);
`;

function ToastsWrapper(): JSX.Element {
    const { toasts, removeToast } = useToast();

    return createPortal(
        <Container className="toast-container">
            {toasts.map((toast) => (
                <ToastMessage key={toast.id} toast={toast} onRemove={removeToast} />
            ))}
        </Container>,
        document.body,
    );
}

export default ToastsWrapper;
