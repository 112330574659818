import {
    autoUpdate,
    flip,
    FloatingPortal,
    offset,
    shift,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import InfoIcon from 'assets/icons/question_mark_solid.svg?react';
import { JSX, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusM, borderRadiusS, primaryMerBlue, spaceXs, spaceXxs } from 'styles/variables';
import { ETrackingEvent, IPopupTracking } from 'types/tracking';

const PopUpButton = styled.button`
    background: transparent;
    border: none;
    border-radius: ${borderRadiusS};
    line-height: 0;
    width: max-content;
    height: max-content;
    color: ${primaryMerBlue};
    font-size: inherit;
`;

const ReadMore = styled.span`
    display: inline-block;
    padding: 0 ${spaceXxs};
    line-height: 1;
`;

const IconContainer = styled.div`
    padding: ${spaceXs} ${spaceXs} ${spaceXs} 0;
`;

const Tooltip = styled.div`
    background-color: white;
    max-width: 26rem;
    border-radius: ${borderRadiusM};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
`;

type PopUpProp = {
    icon?: React.ReactNode;
    readMore?: boolean;
    content?: React.ReactNode | React.ReactNode[];
    dataTestId?: string;
    gaTracking?: IPopupTracking;
};

function PopUp({ icon, content, dataTestId, gaTracking, readMore }: PopUpProp): JSX.Element {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(5),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            shift(),
        ],
    });

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

    const popUpTrigger = readMore ? (
        <ReadMore>{t('general.readMore')}</ReadMore>
    ) : (
        <IconContainer>{icon ?? <InfoIcon data-testid="defaultIcon" />}</IconContainer>
    );

    useEffect(() => {
        if (isOpen && gaTracking) {
            ReactGA.event(ETrackingEvent.INFO_POPUP, {
                type: gaTracking.type,
                origin: gaTracking.origin,
            });
        }
    }, [gaTracking, isOpen]);

    return (
        <>
            <PopUpButton
                ref={refs.setReference}
                {...getReferenceProps()}
                type="button"
                className="popUpButton"
                data-testid={dataTestId ?? 'popupButton'}
                aria-label={t('general.readMore')}
            >
                {popUpTrigger}
            </PopUpButton>
            {isOpen && (
                <FloatingPortal>
                    <Tooltip className="Tooltip" ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
                        {content}
                    </Tooltip>
                </FloatingPortal>
            )}
        </>
    );
}

export default PopUp;
