import SubscriptionDetailsPanel from '../../components/subscriptions/subscriptionDetails/SubscriptionDetailsPanel';
import SubscriptionStatusPopUp from '../../components/subscriptions/SubscriptionStatusPopUp';
import ExportButton from 'components/clickables/ExportButton';
import FilterButton from 'components/clickables/FilterButton';
import { UtilityBar, UtilityBarWrapper } from 'components/elements/UtilityBar';
import SearchInput from 'components/forms/SearchInput';
import StatusPill from 'components/info/StatusPill';
import TextEllipsisComponent, { TextEllipsis } from 'components/info/TextEllipsisComponent';
import Page from 'components/layout/Page';
import SidePanel from 'components/layout/SidePanel';
import GridList, { GridListHeader, GridListItem, GridListItemButton, Header } from 'components/lists/GridList';
import ListFooter from 'components/lists/ListFooter';
import SortableColumnHeader from 'components/lists/SortableColumnHeader';
import SubscriptionOverviewFilter from 'components/subscriptions/SubscriptionOverviewFilter';
import SelectedFilterBar from 'components/widgets/SelectedFilterBar';
import { PAGE_SIZE } from 'constants/general';
import { pageKeys } from 'constants/pageKeys';
import { useModal } from 'contexts/Modal';
import useBusinessId from 'hooks/useBusinessId';
import { useDate } from 'hooks/useDate';
import { useExport } from 'hooks/useExport';
import useSubscriptions from 'hooks/useSubscriptions';
import useSubscriptionsExport from 'hooks/useSubscriptionsExport';
import { JSX, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { darkGrey } from 'styles/variables';
import { EExportFileType, ESortingOrder } from 'types';
import { ESubscriptionSortingColumns, ESubscriptionStatus, Subscription } from 'types/subscription';
import { ETrackingEvent, ETrackingOrigin } from 'types/tracking';

const gridColumn = '7rem minmax(3rem, 1fr) 7rem minmax(3rem, 1fr) 9.375rem minmax(3rem, 1fr)';

const StyledTextEllipsis = styled(TextEllipsis)`
    color: ${darkGrey};
`;

type FilterData = {
    status: string;
};

function SubscriptionOverviewPage(): JSX.Element {
    const { t } = useTranslation();
    const { open } = useModal();
    const sidePanelRef = useRef<HTMLDivElement>(null); // Ref for the sidebar
    const productListRef = useRef<HTMLDivElement>(null); // Ref for the product list
    const [isOpen, setIsOpen] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();

    const { formatDate } = useDate();
    const { activeParentBusinessId } = useBusinessId();

    const [filterData, setFilterData] = useState<FilterData>({
        status: '',
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [sorting, setSorting] = useState({
        sortColumn: ESubscriptionSortingColumns.CREATION_TIME,
        sortDirection: ESortingOrder.DESC,
    });
    const searchPageNumber = searchParams.get('pageNumber');
    const searchTerm = searchParams.get('searchTerm');

    const querySearchParam = searchParams;

    const firstRender = useRef(searchTerm);
    const [pageNumber, setPageNumber] = useState<number>(searchPageNumber ? Number(searchPageNumber) : 1);

    useEffect(() => {
        if (pageNumber === 1) {
            querySearchParam.set('pageNumber', '1');
        } else {
            querySearchParam.set('pageNumber', String(pageNumber));
        }
        if (filterData.status) {
            querySearchParam.set('status', filterData.status.toLocaleLowerCase());
        } else {
            querySearchParam.delete('status');
        }
        setSearchParams(querySearchParam);
    }, [filterData.status, pageNumber, querySearchParam, setSearchParams]);

    useEffect(() => {
        if (searchTerm !== firstRender.current) {
            setPageNumber(1);
        }
    }, [searchTerm]);

    const [fileType, setFileType] = useState<EExportFileType>(EExportFileType.EXCEL);

    const subscriptionsQueryData = {
        parentBusinessId: activeParentBusinessId,
        pageNumber: pageNumber - 1,
        pageSize: PAGE_SIZE,
        searchTerm: searchTerm ?? '',
        sortColumn: sorting.sortColumn,
        sortDirection: sorting.sortDirection,
        fileType,
        status: filterData.status,
    };

    const {
        subscriptionList,
        error: errorSubscriptions,
        isLoading,
        mutateSubscriptions,
    } = useSubscriptions(subscriptionsQueryData);
    const getExport = useSubscriptionsExport(subscriptionsQueryData);

    const haveSubscriptions = subscriptionList && subscriptionList.subscriptions.length >= 1;
    const { startExport, somethingToExport } = useExport(haveSubscriptions);

    const handlePagination = (page: number): void => {
        setPageNumber(page);
    };

    const getSortingDirection = (column: ESubscriptionSortingColumns): ESortingOrder => {
        const direction = sorting.sortColumn === column ? sorting.sortDirection : undefined;
        return direction as ESortingOrder;
    };

    const handleOnSortChange = (sortColumn: ESubscriptionSortingColumns, sortDirection: ESortingOrder): void => {
        setSorting({ sortColumn, sortDirection });
    };

    const openPanel = (sub: Subscription, event: React.MouseEvent): void => {
        event.stopPropagation();
        setSelectedSubscription(sub);
        setIsOpen(true);
    };

    const closePanel = (): void => {
        setIsOpen(false);
    };

    useEffect(() => {
        function handler(e: MouseEvent): void {
            const target = e.target as HTMLElement;
            const closestUl = target.closest('ul');

            if (
                !sidePanelRef?.current?.contains(e.target as Node) &&
                target.getAttribute('data-testid') !== 'languageSelector' &&
                closestUl?.getAttribute('data-testid') !== 'languageList'
            ) {
                const closestLi = target.closest('li');
                if (productListRef.current && closestLi) {
                    const parentList = closestLi.parentElement;

                    if (parentList) {
                        const allListItems = Array.from(parentList.children);
                        const index = allListItems.indexOf(closestLi);

                        if (index === 0) {
                            setSelectedSubscription(undefined);
                            closePanel();
                        }
                    }
                } else if (!productListRef.current?.contains(e.target as Node)) {
                    setSelectedSubscription(undefined);
                    closePanel();
                }
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, [sidePanelRef]);

    const handleOnFilter = (filters: { status: string }): void => {
        setFilterData(filters);
        setPageNumber(1);
    };

    const handleOnClearFilter = (value: string): void => {
        if (value === 'status') {
            setFilterData({
                status: '',
            });
        }
    };
    const showFilter = (): void => {
        open(<SubscriptionOverviewFilter onFilter={handleOnFilter} filterData={filterData} />);
    };

    const selectedFilters = useMemo(() => {
        const filterArray = [];
        if (filterData.status) {
            filterArray.push({
                text: t(`status.${[filterData.status.toLocaleLowerCase()]}`),
                value: 'status',
            });
        }
        return filterArray;
    }, [filterData.status, t]);

    return (
        <>
            <Page title={t('subscription.overview.title')} pageKey={pageKeys.SUBSCRIPTION_OVERVIEW}>
                <UtilityBarWrapper $hasSelectedFilters={selectedFilters.length > 0}>
                    <UtilityBar>
                        <SearchInput placeholder={t('subscription.subscriptionList.searchPlaceHolder')} />
                        <FilterButton dataTestId="filterButton" showFilter={showFilter} />
                        <ExportButton
                            setFileType={setFileType}
                            isLoading={getExport.isPending}
                            getExport={() => {
                                ReactGA.event(ETrackingEvent.EXPORT_FILE, {
                                    origin: ETrackingOrigin.SUBSCRIPTION,
                                });
                                startExport(() => getExport.mutateAsync(), haveSubscriptions);
                            }}
                            showMessage={!somethingToExport}
                        />
                    </UtilityBar>
                    {selectedFilters.length > 0 && (
                        <SelectedFilterBar
                            selectedFilters={selectedFilters}
                            onClearFilter={handleOnClearFilter}
                            onItemClick={showFilter}
                            className="selected-filters"
                        />
                    )}
                </UtilityBarWrapper>
                <GridList
                    ref={productListRef}
                    gridColumns={gridColumn}
                    clickable
                    dataTestId="subscriptionsList"
                    showEmptyListMessage={subscriptionList?.subscriptions.length === 0 && !isLoading}
                    isLoading={!haveSubscriptions && isLoading}
                    hasError={!!errorSubscriptions}
                >
                    <GridListHeader>
                        <SortableColumnHeader
                            sortingDirection={getSortingDirection(ESubscriptionSortingColumns.STATUS)}
                            onSortChange={(direction) =>
                                handleOnSortChange(ESubscriptionSortingColumns.STATUS, direction as ESortingOrder)
                            }
                            infoButton={<SubscriptionStatusPopUp gaTrackingOrigin={ETrackingOrigin.SUBSCRIPTION} />}
                            text={t('general.status')}
                        />
                        <Header>{t('general.name')}</Header>
                        <Header>{t('general.licencePlate')}</Header>
                        <Header>{t('general.reference')}</Header>
                        <SortableColumnHeader
                            sortingDirection={getSortingDirection(ESubscriptionSortingColumns.CREATION_TIME)}
                            onSortChange={(direction) =>
                                handleOnSortChange(
                                    ESubscriptionSortingColumns.CREATION_TIME,
                                    direction as ESortingOrder,
                                )
                            }
                            text={t('general.startDate')}
                        />
                        <Header>{t('general.subscription')}</Header>
                    </GridListHeader>
                    {haveSubscriptions && !errorSubscriptions && (
                        <>
                            {subscriptionList.subscriptions.map((sub) => (
                                <GridListItem
                                    key={sub.id}
                                    className={selectedSubscription?.id === sub.id ? 'selected' : ''}
                                >
                                    <GridListItemButton
                                        data-testid={`listItemButton_${sub.id}`}
                                        key={sub.id}
                                        onClick={(event) => openPanel(sub, event)}
                                    >
                                        <StatusPill status={sub.status as ESubscriptionStatus} />
                                        {!sub.driverName ? (
                                            <StyledTextEllipsis>{t('subscription.unpersonalised')}</StyledTextEllipsis>
                                        ) : (
                                            <TextEllipsisComponent tooltipText={sub.driverName}>
                                                {sub.driverName}
                                            </TextEllipsisComponent>
                                        )}
                                        <TextEllipsis>{sub.licencePlate}</TextEllipsis>
                                        <TextEllipsisComponent tooltipText={sub.reference}>
                                            {sub.reference}
                                        </TextEllipsisComponent>
                                        <p>{formatDate(sub.startDate)}</p>
                                        <TextEllipsisComponent tooltipText={sub.productType}>
                                            {sub.productType}
                                        </TextEllipsisComponent>
                                    </GridListItemButton>
                                </GridListItem>
                            ))}
                        </>
                    )}
                </GridList>
                {haveSubscriptions && (
                    <ListFooter data={subscriptionList} handlePagination={handlePagination} pageNumber={pageNumber} />
                )}
            </Page>
            <SidePanel
                isOpen={isOpen}
                onClose={closePanel}
                title={t('subscription.details.subscriptionsTitle')}
                ref={sidePanelRef}
            >
                {selectedSubscription && (
                    <SubscriptionDetailsPanel
                        selectedSubscription={selectedSubscription}
                        sidePanelOpen={isOpen}
                        onSave={() => mutateSubscriptions()}
                        mutateSubscriptions={mutateSubscriptions}
                    />
                )}
            </SidePanel>
        </>
    );
}

export default SubscriptionOverviewPage;
