import Button from 'components/clickables/Button';
import useAuthentication from 'hooks/useAuthentication';
import { JSX, useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { ButtonVariant } from 'types';
import { ETrackingEvent } from 'types/tracking';

type LogoutButtonProps = {
    className?: string;
    variant?: ButtonVariant;
    onLogout?: () => void;
};

function LogoutButton({ className, variant, onLogout }: LogoutButtonProps): JSX.Element {
    const { logoutRedirectRegular } = useAuthentication();
    const { t } = useTranslation();

    const handleOnClick = useCallback(() => {
        ReactGA.event(ETrackingEvent.LOGOUT);
        if (onLogout) {
            onLogout();
        }
        logoutRedirectRegular(true);
    }, [logoutRedirectRegular, onLogout]);

    return (
        <Button dataTestId="userMenuLogoutButton" onClick={handleOnClick} className={className} variant={variant}>
            {t('general.logout')}
        </Button>
    );
}

export default LogoutButton;
