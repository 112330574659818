import UserMenu from 'components/userMenu/UserMenu';
import { PATH } from 'constants/paths';
import useAuthentication from 'hooks/useAuthentication';
import useBusinesses from 'hooks/useBusinesses';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useGetBusiness from 'hooks/useGetBusiness';
import useLocalState from 'hooks/useLocalState';
import { JSX, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { font, headerHeight, lightBlue, primaryMerBlue, spaceS, spaceXs, white } from 'styles/variables';
import { ETrackingEvent } from 'types/tracking';

const Container = styled.div`
    position: relative;
`;

const UserNameHeader = styled.span`
    display: inline-block;
    visibility: visible;
    text-align: right;
    font-family: ${font.body};
    font-size: ${font.size.s};
`;

const ToggleNavigation = styled.button`
    display: flex;
    align-items: center;
    justify-content: end;
    gap: ${spaceS};
    padding: ${spaceXs} ${spaceS};
    height: ${headerHeight};
    border: none;
    -webkit-appearance: none;
    font-family: ${font.body};
    background-color: ${white};
    font-size: ${font.size.s};

    .initials {
        height: 2.5rem;
        width: 2.5rem;
        min-width: 2.5rem;
        line-height: ${font.lineHeight.xxl};
        text-align: center;
        font-size: ${font.size.m};
        font-family: ${font.semiBold};
        background-color: ${primaryMerBlue};
        color: ${white};
        border-radius: 50%;
    }

    &:focus {
        outline: none;
    }

    &:hover {
        background: ${lightBlue};
    }
`;

export default function UserMenuButton(): JSX.Element | null {
    const navigate = useNavigate();
    const { getAccountName, getAccountNameInitials, getAccountEmail } = useAuthentication();
    const name = getAccountName();
    const initials = getAccountNameInitials();
    const email = getAccountEmail();
    const [userMenuVisible, setUserMenuVisible] = useState<boolean>(false);
    const domRef = useRef<HTMLDivElement>(null);

    const { businesses } = useBusinesses();
    const { getActiveBusiness } = useGetBusiness(businesses);
    const activeBusiness = getActiveBusiness();

    const { locale } = useCountrySpecificContent();
    const sortedBusinesses = useMemo(
        () => businesses.sort((a, b) => a.name.localeCompare(b.name, locale)),
        [businesses, locale],
    );

    const {
        actions: { removeAllBulkImportSubscriptionOrder },
    } = useLocalState();

    useEffect(() => {
        function handler(e: MouseEvent): void {
            if (!domRef.current?.contains(e.target as HTMLDivElement)) {
                setUserMenuVisible(false);
            }
        }

        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);

    const handleOnOrganisationChange = (parentBusinessId: string): void => {
        navigate(PATH.STARTPAGE);
        setUserMenuVisible(false);
        localStorage.setItem('activeParentBusinessId', parentBusinessId);
        removeAllBulkImportSubscriptionOrder();
        ReactGA.event(ETrackingEvent.CHANGE_ORGANISATION);
    };

    return (
        <Container className="userMenu" ref={domRef as unknown as MutableRefObject<HTMLDivElement>}>
            <ToggleNavigation
                data-testid="userMenuButton"
                type="button"
                onClick={() => setUserMenuVisible(!userMenuVisible)}
            >
                <UserNameHeader>{activeBusiness?.name}</UserNameHeader>
                <span className="initials">{initials}</span>
            </ToggleNavigation>
            {userMenuVisible && (
                <UserMenu
                    userName={name}
                    userEmail={email}
                    onOrganisationChange={handleOnOrganisationChange}
                    businesses={sortedBusinesses}
                    activeBusinessId={activeBusiness?.id}
                />
            )}
        </Container>
    );
}
