import StatusPill from 'components/info/StatusPill';
import { JSX, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { errorColor, panelBackgroundColorBlue, white } from 'styles/variables';
import { EConnectorStatus } from 'types/facility';

const StyledStatusPill = styled(StatusPill)`
    max-width: 7.5rem;
    &.highlight {
        &.red {
            border-color: ${errorColor};
            background-color: ${errorColor};
            color: ${white};
        }

        &.default {
            background-color: ${panelBackgroundColorBlue};
            border-color: ${panelBackgroundColorBlue};
        }

        animation: highlight 2s infinite;
    }

    @keyframes highlight {
        0% {
            filter: brightness(1);
        }
        50% {
            filter: brightness(0.9);
        }
        100 {
            filter: brightness(1);
        }
    }
`;

function ConnectorStatus({ status }: { status: EConnectorStatus }): JSX.Element {
    const [highlight, setHighlight] = useState(false);
    const prevValue = useRef(status);

    useEffect(() => {
        if (prevValue.current !== status) {
            setHighlight(true);
            const timer = setTimeout(() => setHighlight(false), 2000);
            prevValue.current = status;
            return () => clearTimeout(timer);
        }
        return undefined;
    }, [status]);

    return (
        <StyledStatusPill
            status={status}
            className={`${highlight ? 'highlight' : ''} ${status === EConnectorStatus.FAULTED ? 'red' : 'default'}`}
            dataTestId={`connectorStatus_${status.toLocaleLowerCase()}`}
        />
    );
}

export default ConnectorStatus;
