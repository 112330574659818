import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import PopUp from 'components/info/PopUp';
import TooltipContentComponent from 'components/info/TooltipContent';
import KpiContainer from 'components/kpi/KpiContainer';
import KpiListItem, { KpiList, KpiListTotal } from 'components/kpi/KpiListItem';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import { useCurrency } from 'hooks/useCurrency';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, spaceS, spaceXxs } from 'styles/variables';
import { ECurrency } from 'types';
import { CostItem, DynamicKpiData, EUsageType } from 'types/subscription';

const StyledKpiListItem = styled(KpiListItem)<{ className: string }>`
    &.${({ className }) => className}:not(:has(~ .${({ className }) => className})):not(:last-child) {
        margin-bottom: ${spaceS};
    }
`;

const KpiListHeader = styled.h3`
    font-size: ${font.size.m};
    margin-bottom: ${spaceXxs};
`;

type AdditionalCosts = {
    cost: CostItem;
    type: EUsageType;
};

type Cost = {
    amount: number;
    type: string;
};

type CostGroup = {
    currency: string;
    cost: Cost[];
};

type VariableCostProps = {
    data?: DynamicKpiData;
};

function VariableCost({ data }: VariableCostProps): JSX.Element {
    const { t } = useTranslation();
    const { currency } = useCountrySpecificContent();
    const { toCurrency } = useCurrency();

    const getCostByTypeAndBUCurrency = (costItems: CostItem[]): CostItem => {
        return (
            costItems.find((cost) => cost.currency === currency) ?? {
                amount: 0,
                currency,
            }
        );
    };

    const totalCostForBUCurrency = getCostByTypeAndBUCurrency(data?.variableCost ?? []);
    const internalCostForBUCurrency = getCostByTypeAndBUCurrency(data?.internalLocationCost ?? []);
    const publicCostForBUCurrency = getCostByTypeAndBUCurrency(data?.publicLocationCost ?? []);

    const totalAdditionalInternalCosts = data?.internalLocationCost?.filter((cost) => cost.currency !== currency);
    const totalAdditionalPublicCosts = data?.publicLocationCost?.filter((cost) => cost.currency !== currency);

    const totalAdditionalCosts: AdditionalCosts[] = [];
    const addCostToTotalAdditionalCosts = (costs: CostItem[], type: EUsageType): void => {
        costs.forEach((cost) => {
            totalAdditionalCosts.push({
                cost,
                type,
            });
        });
    };

    if (totalAdditionalInternalCosts) {
        addCostToTotalAdditionalCosts(totalAdditionalInternalCosts, EUsageType.INTERNAL);
    }

    if (totalAdditionalPublicCosts) {
        addCostToTotalAdditionalCosts(totalAdditionalPublicCosts, EUsageType.PUBLIC);
    }

    const haveCostsInOtherCurrencies = totalAdditionalCosts.length > 0;

    const totalAdditionalCostsArray: CostGroup[] = totalAdditionalCosts.reduce((acc: CostGroup[], current) => {
        const existingGroupIndex = acc.findIndex((group) => group.currency === current.cost.currency);
        if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].cost.push({
                amount: current.cost.amount,
                type: current.type,
            });
        } else {
            acc.push({
                currency: current.cost.currency,
                cost: [
                    {
                        amount: current.cost.amount,
                        type: current.type,
                    },
                ],
            });
        }
        return acc;
    }, []);

    // Sort each cost group based on type
    const sortedAdditionalCostsArray = totalAdditionalCostsArray.map((costGroup) => {
        const sortedCosts = [...costGroup.cost].sort((a, b) => a.type.localeCompare(b.type));
        return {
            ...costGroup,
            cost: sortedCosts,
        };
    });

    return (
        <KpiContainer
            dataTestId="variableCostKpi"
            title={t('subscription.statistics.kpi.variableCost')}
            content={
                <>
                    <KpiList data-testid="variableCostListBuCurrency">
                        <KpiListTotal
                            dataTestId="totalCostBuCurrency"
                            title={t('general.total')}
                            value={toCurrency({ amount: totalCostForBUCurrency.amount, currency })}
                        />
                        <KpiListItem
                            dataTestId="internalCostBuCurrency"
                            title={t('usageType.INTERNAL')}
                            value={toCurrency({ amount: internalCostForBUCurrency.amount, currency })}
                        />
                        <KpiListItem
                            dataTestId="publicCostBuCurrency"
                            title={t('usageType.PUBLIC')}
                            value={toCurrency({ amount: publicCostForBUCurrency.amount, currency })}
                        />
                    </KpiList>
                    {haveCostsInOtherCurrencies && (
                        <>
                            <KpiListHeader>{t('subscription.statistics.kpi.totalCost.additionalCost')}</KpiListHeader>
                            <KpiList data-testid="variableCostListOtherCurrencies">
                                {sortedAdditionalCostsArray.map((currencyCosts) =>
                                    currencyCosts.cost.map((cost) => (
                                        <StyledKpiListItem
                                            dataTestId={`${cost.type.toLowerCase()}Cost_${currencyCosts.currency}`}
                                            className={currencyCosts.currency}
                                            key={cost.amount}
                                            title={t(`usageType.${cost.type}`)}
                                            value={toCurrency({
                                                amount: cost.amount,
                                                currency: currencyCosts.currency as ECurrency,
                                            })}
                                        />
                                    )),
                                )}
                            </KpiList>
                        </>
                    )}
                </>
            }
            tooltip={
                <PopUp
                    dataTestId="variableCostInfo"
                    content={
                        <TooltipContentComponent
                            headline={t('subscription.statistics.kpi.infoBoxVariableCost.headline')}
                            text={t('subscription.statistics.kpi.infoBoxVariableCost.text')}
                            icon={<QuestionMarkTooltipIcon />}
                        />
                    }
                />
            }
        />
    );
}

export default VariableCost;
