import KpiContainer from 'components/kpi/KpiContainer';
import KpiListItem, { KpiList, KpiListTotal } from 'components/kpi/KpiListItem';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumedEnergy } from 'types/subscription';
import { getTotalKwh } from 'utils/subscription/chart';

type TotalKwhKpiProps = {
    data?: ConsumedEnergy[];
};

function TotalKwhKpi({ data = [] }: TotalKwhKpiProps): JSX.Element {
    const { t } = useTranslation();
    const subscriptionData = data.toSorted((a, b) => Number(b.kWh) - Number(a.kWh));
    const totalKwh = getTotalKwh(data).toFixed(2);
    const { formatNumber } = useNumber();

    return (
        <KpiContainer
            dataTestId="totalKwhKpi"
            title={t('subscription.statistics.kpi.totalKwh')}
            content={
                <KpiList data-testid="totalKwhList">
                    <KpiListTotal
                        dataTestId="totalKwh"
                        title={t('general.total')}
                        value={`${formatNumber(Number(totalKwh))} kWh`}
                    />
                    {subscriptionData.map((item) => {
                        return (
                            <KpiListItem
                                key={item.name}
                                title={item.name}
                                value={`${formatNumber(Number(item.kWh))} kWh`}
                            />
                        );
                    })}
                </KpiList>
            }
        />
    );
}

export default TotalKwhKpi;
