import AppRoutes from 'AppRoutes';
import FullPageLoadingIndicator from 'components/indicators/FullPageLoadingIndicator';
import useBusinesses from 'hooks/useBusinesses';
import useGetBusiness from 'hooks/useGetBusiness';
import { useLanguage } from 'hooks/useLanguage';
import useUserPreferences from 'hooks/useUserPreferences';
import i18n from 'i18n';
import PageWithoutRole from 'pages/PageWithoutRole';
import { JSX, useEffect, useMemo } from 'react';
import { ELocale } from 'types';

function RouteContentWrapper(): JSX.Element {
    const { businesses, isLoading } = useBusinesses();
    const { getBusiness } = useGetBusiness(businesses ?? []);
    const activeBusiness = getBusiness(localStorage.getItem('activeParentBusinessId') ?? '');

    const { userPreferences, isLoading: isFirebaseLoading } = useUserPreferences();

    const { getValidBULanguage } = useLanguage();

    useEffect(() => {
        if (userPreferences?.preferredLanguage !== undefined) {
            i18n.changeLanguage(getValidBULanguage(userPreferences.preferredLanguage as ELocale));
        }
    }, [getValidBULanguage, isFirebaseLoading, userPreferences]);

    const hasFeatures = useMemo(() => {
        if (!isLoading && businesses) {
            let hasAnyFeatures = false;
            businesses.forEach((business) => {
                if (business.features?.length && business.features?.length > 0) {
                    hasAnyFeatures = true;
                }
                business.subsidiaries?.forEach((sub) => {
                    if (sub.features?.length && sub.features?.length > 0) {
                        hasAnyFeatures = true;
                    }
                });
            });
            return hasAnyFeatures;
        }
        return false;
    }, [businesses, isLoading]);

    if (
        businesses &&
        hasFeatures &&
        !isLoading &&
        (!localStorage.getItem('activeParentBusinessId') || !activeBusiness)
    ) {
        localStorage.setItem('activeParentBusinessId', businesses[0].id);
    }

    if (!isLoading && !hasFeatures) {
        return <PageWithoutRole />;
    }
    if (isLoading || isFirebaseLoading) {
        return <FullPageLoadingIndicator />;
    }
    return <AppRoutes />;
}

export default RouteContentWrapper;
