import PropertyList from 'components/facility/PropertyList';
import StatusWidget from 'components/facility/StatusWidget';
import DropdownInput from 'components/forms/DropdownInput';
import SearchInput from 'components/forms/SearchInput';
import Page from 'components/layout/Page';
import { pageKeys } from 'constants/pageKeys';
import useBusinesses from 'hooks/useBusinesses';
import useConnectorStatus from 'hooks/useConnectorStatus';
import useGetBusiness from 'hooks/useGetBusiness';
import useProperties from 'hooks/useProperties';
import { JSX, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { spaceM } from 'styles/variables';
import { EBusinessFeature } from 'types/business';
import { ICharger, IConnector, IConnectorStatus, IProperty, ISite } from 'types/facility';
import { getListWithFilteredChargersByBusinessId } from 'utils/facility';

const Container = styled.section`
    display: flex;
    gap: 1rem;
    > * {
        max-width: 400px;
    }
`;

const SearchInputWrapper = styled.div`
    margin-bottom: ${spaceM};
`;

function FacilityChargersPage(): JSX.Element {
    const { t } = useTranslation();
    const { businesses } = useBusinesses();
    const { getBusinessesListByFeature, getActiveBusiness } = useGetBusiness(businesses);
    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const [connectorState, setConnectorState] = useState<IConnectorStatus[]>([]);

    const handleToggleSection = (id: string): void => {
        setExpandedItems((prev: string[]) => {
            if (prev.includes(id)) {
                return prev.filter((item: string) => item !== id);
            }
            return [...prev, id];
        });
    };

    const organisationList = getBusinessesListByFeature(
        [EBusinessFeature.FACILITY_MANAGEMENT_EDIT, EBusinessFeature.FACILITY_MANAGEMENT_VIEW],
        true,
    );

    const { properties, isLoading } = useProperties(getActiveBusiness()?.id ?? '');

    const updateConnectorStatus = (data: IConnectorStatus): void => {
        setConnectorState((prevState) => {
            const foundConnector = prevState?.find((c) => c.evseId === data.evseId);
            if (foundConnector) {
                return prevState.map((connector) =>
                    connector.evseId === foundConnector.evseId ? { ...connector, ...data } : connector,
                );
            }

            return [...prevState, data];
        });
    };

    useConnectorStatus(properties, updateConnectorStatus);

    const allOption = useMemo(() => {
        const option = { text: t('form.input.property.allPropertiesOption'), value: 'all' };
        return option;
    }, [t]);

    const noPropertyOption = useMemo(() => {
        const option = { value: '', text: t('facility.noProperties') };
        return option;
    }, [t]);

    const propertyList = useMemo(() => {
        const mappedList = properties.map((property) => ({
            value: property.name,
            text: property.name,
        }));
        return properties.length > 1 ? [allOption, ...mappedList] : mappedList;
    }, [properties, allOption]);

    const { register, watch, resetField, setValue } = useForm({
        defaultValues: {
            organisationDropdown: organisationList[0]?.value,
            propertyDropdown: 'all',
        },
    });

    useEffect(() => {
        if (!isLoading) {
            const propertyValue = properties.length === 0 ? '' : 'all';
            setValue('propertyDropdown', propertyValue);
        }
    }, [isLoading, properties.length, setValue]);

    const selectedOrganisation = watch('organisationDropdown');
    const selectedProperty = watch('propertyDropdown');

    const filteredPropertyList = useMemo(() => {
        if (selectedOrganisation?.includes('all') || organisationList.length === 1) {
            return propertyList.length === 0 ? [noPropertyOption] : propertyList;
        }
        const filteredProperties = properties
            .filter((p) => p.chargerOwnerBusinessIds.includes(selectedOrganisation))
            .map((property) => ({ value: property.name, text: property.name }));
        if (filteredProperties.length === 0) {
            return [noPropertyOption];
        }
        return filteredProperties.length > 1 ? [allOption, ...filteredProperties] : filteredProperties;
    }, [selectedOrganisation, organisationList.length, properties, allOption, propertyList, noPropertyOption]);

    const propertyListData = useMemo(() => {
        const propertiesList = [...properties];
        if (selectedOrganisation?.includes('all') && selectedProperty === 'all') {
            return propertiesList;
        }
        if (selectedOrganisation?.includes('all') && selectedProperty !== 'all') {
            return propertiesList.filter((p) => p.name === selectedProperty);
        }
        if (!selectedOrganisation?.includes('all') && selectedProperty === 'all') {
            const filteredList = propertiesList.filter((p) => p.chargerOwnerBusinessIds.includes(selectedOrganisation));
            return getListWithFilteredChargersByBusinessId(filteredList, selectedOrganisation);
        }
        const list = propertiesList.filter(
            (p) => p.chargerOwnerBusinessIds.includes(selectedOrganisation) && p.name === selectedProperty,
        );
        return getListWithFilteredChargersByBusinessId(list, selectedOrganisation);
    }, [properties, selectedOrganisation, selectedProperty]);

    useEffect(() => {
        if (selectedOrganisation) {
            resetField('propertyDropdown');
        }
    }, [selectedOrganisation, resetField]);

    const noPropertiesAvailable = filteredPropertyList.length === 1 && filteredPropertyList[0].value === '';

    const [searchParams] = useSearchParams();
    const searchTerm = searchParams.get('searchTerm')?.toLowerCase() ?? '';

    const onSearch = useMemo((): { data: IProperty[]; matchesFound: string[] } => {
        const matchesFound: string[] = [];

        if (!searchTerm) return { data: propertyListData, matchesFound };

        const filteredProperties = propertyListData.reduce((acc: IProperty[], property) => {
            const filteredSites = property.sites.reduce((siteAcc: ISite[], site) => {
                const filteredChargers = site.chargers.reduce((chargerAcc: ICharger[], charger) => {
                    const matchesIdentityKey = charger.identityKey.toLowerCase().includes(searchTerm);
                    const filteredConnectors = charger.connectors.reduce((connAcc: IConnector[], connector) => {
                        if (connector.evseId.toLowerCase().includes(searchTerm)) {
                            connAcc.push(connector);
                        }
                        return connAcc;
                    }, []);

                    if (matchesIdentityKey) {
                        chargerAcc.push(charger);
                        matchesFound.push(charger.identityKey);
                    } else if (filteredConnectors.length > 0) {
                        chargerAcc.push({ ...charger, connectors: filteredConnectors });
                        matchesFound.push(charger.identityKey);
                    }

                    return chargerAcc;
                }, []);

                if (site.name.toLowerCase().includes(searchTerm)) {
                    siteAcc.push({ ...site });
                    matchesFound.push(site.id);
                } else if (filteredChargers.length > 0) {
                    siteAcc.push({ ...site, chargers: filteredChargers });
                    matchesFound.push(site.id);
                }
                return siteAcc;
            }, []);

            if (filteredSites.length > 0) {
                acc.push({ ...property, sites: filteredSites });
            }

            return acc;
        }, []);

        return { data: filteredProperties, matchesFound };
    }, [propertyListData, searchTerm]);

    const { data: filteredProperties, matchesFound } = onSearch;

    useEffect(() => {
        if (searchTerm) {
            setExpandedItems(matchesFound);
        }
        if (filteredProperties.length !== 0 && matchesFound.length === 0 && !searchTerm) {
            setExpandedItems([]);
        }
    }, [matchesFound, searchTerm, filteredProperties]);

    return (
        <Page
            pageKey={pageKeys.FACILITY_CHARGERS}
            title={t('facility.chargers.title')}
            dataTestId="facilityChargersPage"
            isLoading={isLoading}
        >
            <Container>
                {organisationList.length > 1 && (
                    <DropdownInput
                        dataTestId="organisationDropdown"
                        options={organisationList}
                        hidePlaceholderOption
                        {...register('organisationDropdown')}
                    />
                )}
                <DropdownInput
                    dataTestId="propertyDropdown"
                    options={filteredPropertyList}
                    hidePlaceholderOption
                    disabled={noPropertiesAvailable}
                    {...register('propertyDropdown')}
                    value={watch('propertyDropdown')}
                />
            </Container>
            <StatusWidget properties={propertyListData} connectorState={connectorState} />
            <SearchInputWrapper>
                <SearchInput placeholder={t('facility.chargers.searchPlaceHolder')} />
            </SearchInputWrapper>
            <PropertyList
                properties={filteredProperties}
                toggleSection={handleToggleSection}
                expandedItems={expandedItems}
                connectorState={connectorState}
            />
        </Page>
    );
}

export default FacilityChargersPage;
