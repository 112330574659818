import SubscriptionImportGrid from './SubscriptionImportGrid';
import ApiError from 'classes/ApiError';
import Button from 'components/clickables/Button';
import SearchInput from 'components/forms/SearchInput';
import NotificationMessage, { EInfoType } from 'components/info/NotificationMessage';
import Page from 'components/layout/Page';
import SidePanel from 'components/layout/SidePanel';
import ImportFileModal from 'components/subscriptions/ImportFileModal';
import RejectSubscriptionModal from 'components/subscriptions/RejectSubscriptionModal';
import SubscriptionImportForm from 'components/subscriptions/SubscriptionImportForm';
import { pageKeys } from 'constants/pageKeys';
import { useModal } from 'contexts/Modal';
import { useToast } from 'contexts/Toast';
import useBusinesses from 'hooks/useBusinesses';
import { useCountrySpecificContent } from 'hooks/useCountrySpecificContent';
import useLocalState from 'hooks/useLocalState';
import useSubscriptionBulk from 'hooks/useSubscriptionBulk';
import { JSX, useCallback, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { modalWidthMedium, screenWidthMini, spaceL, spaceM, spaceS } from 'styles/variables';
import { ButtonType, ButtonVariant, DropDownType, EToastType } from 'types';
import { IBulkSubscriptionType } from 'types/subscription';
import { ETrackingAction, ETrackingEvent } from 'types/tracking';
import { scrollToTop } from 'utils/scrollToTop';
import { sanitizeBulkSubscriptionOrders } from 'utils/subscription/sanitizeSubscriptionData';
import { validateContactsEmail } from 'utils/subscription/validateBulkSubscription';

const UtilityBar = styled.section`
    display: flex;
    gap: ${spaceM};
    margin-bottom: ${spaceM};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: ${spaceL} 0;
    gap: ${spaceS};
`;

const NotificationMessageWrapper = styled.div`
    max-width: ${screenWidthMini};
    margin-bottom: ${spaceM};
`;

function SubscriptionImportPage(): JSX.Element {
    const { t } = useTranslation();
    const { open, setModalWidth, lockModal, setCloseButtonVisible } = useModal();
    const { businesses, isLoading: isLoadingBusinesses, error: errorBusinesses } = useBusinesses();

    const sidePanelRef = useRef<HTMLDivElement>(null); // Ref for the sidebar
    const [selectedSubscription, setSelectedSubscription] = useState<IBulkSubscriptionType>();
    const [organisationList, setOrganisationList] = useState<DropDownType[] | undefined>();
    const [isOpen, setIsOpen] = useState(false);

    const { addToast } = useToast();
    const {
        actions: {
            removeAllBulkImportSubscriptionOrder,
            removeBulkImportSubscriptionOrder,
            updateBulkImportSubscriptionOrder,
        },
        state: { bulkImportSubscriptionOrder },
    } = useLocalState();

    const [searchParams] = useSearchParams();
    const searchText = searchParams.get('searchTerm') ?? '';

    const { createSubscriptions, isLoading: isLoadingCreateSubscriptions } = useSubscriptionBulk();

    const subscriptions = useMemo(() => {
        const validatedSubscriptions = validateContactsEmail(bulkImportSubscriptionOrder);
        const text = searchText.toLocaleLowerCase();
        return validatedSubscriptions.filter(
            (subscr) =>
                subscr.reference.toLowerCase().includes(text) ||
                subscr.licencePlate.toLowerCase().includes(text) ||
                subscr.contact?.firstName?.toLowerCase().includes(text) ||
                subscr.contact?.lastName?.toLowerCase().includes(text),
        );
    }, [bulkImportSubscriptionOrder, searchText]);

    const openSubscriptionForm = (
        subscription: IBulkSubscriptionType,
        subscriptionOrganisationList: DropDownType[] | undefined,
        event: React.MouseEvent,
    ): void => {
        event.stopPropagation();
        setSelectedSubscription(subscription);
        setOrganisationList(subscriptionOrganisationList);
        setIsOpen(true);
    };

    const closePanel = (): void => {
        setIsOpen(false);
    };

    const removeOrder = (row: number | undefined): void => {
        if (row === selectedSubscription?.row) {
            closePanel();
        }
        removeBulkImportSubscriptionOrder(row);
    };

    const openBrowseModal = useCallback((): void => {
        setModalWidth(modalWidthMedium);
        open(<ImportFileModal />);
    }, [setModalWidth, open]);

    const deleteAllOrders = useCallback((): void => {
        removeAllBulkImportSubscriptionOrder();
        addToast({ message: t('subscription.importSubscriptions.deleteAll'), type: EToastType.SUCCESS });
    }, [removeAllBulkImportSubscriptionOrder, addToast, t]);

    const deleteAllOrdersModal = useCallback((): void => {
        closePanel();
        setModalWidth(modalWidthMedium);
        setCloseButtonVisible(false);
        lockModal(true);
        open(
            <RejectSubscriptionModal header={t('rejectSubscriptionModal.header.all')} deleteOrder={deleteAllOrders} />,
        );
    }, [setModalWidth, open, t, deleteAllOrders, lockModal, setCloseButtonVisible]);

    const areRowsInvalid = useMemo(() => {
        return subscriptions.some((subscription: IBulkSubscriptionType) => subscription.error);
    }, [subscriptions]);

    const { countryCode } = useCountrySpecificContent();

    const bulkOrderSubmit = useCallback(async () => {
        closePanel();
        try {
            const sanitizedOrders = sanitizeBulkSubscriptionOrders(bulkImportSubscriptionOrder, countryCode);
            ReactGA.event(ETrackingEvent.IMPORT_SUBSCRIPTIONS, {
                action: ETrackingAction.SUBMIT,
                count: sanitizedOrders.length,
            });

            await createSubscriptions
                .mutateAsync({ subscriptionOrders: sanitizedOrders })
                .then(({ subscriptionOrderResponses }) => {
                    let anyFailed = false;

                    subscriptionOrderResponses.forEach((order) => {
                        if (order.created) {
                            removeBulkImportSubscriptionOrder(order.row);
                        } else {
                            anyFailed = true;
                            const subscriptionIndex = subscriptions.findIndex((sub) => sub.row === order.row);
                            if (subscriptionIndex !== -1) {
                                const subscription = { ...subscriptions[subscriptionIndex] };
                                subscription.error = true;
                                subscription.errorType = order.errorType;
                                updateBulkImportSubscriptionOrder(subscription);
                            }
                        }
                    });

                    if (anyFailed) {
                        addToast({
                            message: t('subscription.importSubscriptions.errorMessage'),
                            type: EToastType.ERROR,
                        });
                    } else {
                        addToast({
                            message: t('subscription.importSubscriptions.successMessage'),
                            type: EToastType.SUCCESS,
                        });
                    }
                });
        } catch (e) {
            if (e instanceof ApiError) {
                const message = e.temporary
                    ? t('general.errorToast')
                    : t('subscription.create.createSubscriptionError');
                addToast({ message, type: EToastType.ERROR });
            }
        }
    }, [
        addToast,
        bulkImportSubscriptionOrder,
        createSubscriptions,
        removeBulkImportSubscriptionOrder,
        subscriptions,
        t,
        updateBulkImportSubscriptionOrder,
        countryCode,
    ]);

    const apiErrors = [];
    if (!isLoadingBusinesses && errorBusinesses) {
        apiErrors.push(errorBusinesses);
    }

    return (
        <>
            <Page
                pageKey={pageKeys.BULK_IMPORT}
                dataTestId="subscriptionImportPage"
                title={t('subscription.importSubscriptions.title')}
                errors={apiErrors}
            >
                <>
                    <UtilityBar>
                        <SearchInput placeholder={t('subscription.importSubscriptions.searchPlaceHolder')} />
                        <Button
                            dataTestId="subscriptionImportFileButton"
                            variant={ButtonVariant.PRIMARY}
                            onClick={openBrowseModal}
                        >
                            {t('subscription.importFile')}
                        </Button>
                    </UtilityBar>

                    {areRowsInvalid ? (
                        <NotificationMessageWrapper>
                            <NotificationMessage
                                type={EInfoType.ERROR}
                                message={t('subscription.importSubscriptions.invalidInfoMessage')}
                            />
                        </NotificationMessageWrapper>
                    ) : null}

                    <SubscriptionImportGrid
                        isSubmitting={createSubscriptions.isPending}
                        businesses={businesses}
                        subscriptions={subscriptions}
                        open={open}
                        setModalWidth={setModalWidth}
                        removeBulkImportSubscriptionOrder={removeOrder}
                        addToast={addToast}
                        isLoading={isLoadingBusinesses}
                        openSubscriptionForm={openSubscriptionForm}
                    />
                    {subscriptions.length > 0 ? (
                        <ButtonContainer>
                            <Button
                                dataTestId="subscriptionImportPageDeleteAll"
                                variant={ButtonVariant.SECONDARY}
                                onClick={deleteAllOrdersModal}
                                disabled={isLoadingCreateSubscriptions}
                            >
                                {t('form.button.deleteAll')}
                            </Button>
                            <Button
                                dataTestId="subscriptionImportPageSendOrder"
                                type={ButtonType.SUBMIT}
                                variant={ButtonVariant.PRIMARY}
                                isLoading={isLoadingCreateSubscriptions}
                                onClick={() => {
                                    if (!areRowsInvalid) {
                                        bulkOrderSubmit();
                                    } else {
                                        scrollToTop();
                                    }
                                }}
                            >
                                {t('form.button.sendOrder')}
                            </Button>
                        </ButtonContainer>
                    ) : null}
                </>
            </Page>
            <SidePanel
                key={selectedSubscription?.row}
                isOpen={isOpen}
                onClose={closePanel}
                title={t('subscription.details.subscriptionsTitle')}
                ref={sidePanelRef}
            >
                {selectedSubscription && (
                    <SubscriptionImportForm
                        organisationList={organisationList}
                        formData={selectedSubscription}
                        businesses={businesses}
                        onClose={closePanel}
                    />
                )}
            </SidePanel>
        </>
    );
}
export default SubscriptionImportPage;
