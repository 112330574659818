import SuccessIcon from 'assets/icons/check_circle.svg?react';
import CloseIcon from 'assets/icons/close.svg?react';
import ErrorIcon from 'assets/icons/exclamation_circle_filled.svg?react';
import IconButton from 'components/clickables/IconButton';
import { JSX, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { borderRadiusS, errorColor, font, primaryMerBlue, spaceM, spaceXs, successColor } from 'styles/variables';
import { EToastType } from 'types';

const Container = styled.div`
    border-radius: ${borderRadiusS};
    padding: ${spaceXs};
    color: white;
    display: grid;
    grid-template-columns: ${spaceM} 1fr ${spaceM};
    gap: ${spaceXs};
    width: 19.375rem; //310px;
    &.error {
        background: ${errorColor};
    }
    &.success {
        background: ${successColor};
    }
    &.info {
        background: ${primaryMerBlue};
    }
    &.fade {
        animation: fade-out 1s;
    }

    margin-right: ${spaceXs};
    margin-top: ${spaceXs};
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;

    @keyframes toast-in-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
const Message = styled.p`
    margin: 0;
    font-size: ${font.size.s};
`;

const StyledIconButton = styled(IconButton)`
    color: #f8f8f8c9;
    width: 1.5rem;
    height: 1.5rem;
    background: none;
    &:hover:not(:disabled),
    &:active {
        background: none;
        color: white;
    }
`;

const InfoIcon = styled(ErrorIcon)`
    transform: scaleY(-1);
`;

export type ToastType = {
    id: number;
    message: string;
    type: EToastType;
    fadeTime?: number;
    removeTime?: number;
};

type ToastProps = {
    toast: ToastType;
    onRemove: (v: number) => void;
};

function ToastMessage({ toast: { id, message, type, removeTime = 5000 }, onRemove }: ToastProps): JSX.Element {
    const { t } = useTranslation();
    const [fade, setFade] = useState(false);

    let icon;
    switch (type) {
        case EToastType.SUCCESS:
            icon = <SuccessIcon data-testid="toastSuccessIcon" />;
            break;
        case EToastType.ERROR:
            icon = <ErrorIcon data-testid="toastErrorIcon" />;
            break;
        case EToastType.INFO:
            icon = <InfoIcon data-testid="toastInfoIcon" />;
            break;
        default:
            break;
    }

    useEffect(() => {
        const fadingTimer = setTimeout(() => {
            setFade(true);
        }, removeTime - 1000);

        const timer = setTimeout(() => {
            onRemove(id);
        }, removeTime);

        return () => {
            clearTimeout(timer);
            clearTimeout(fadingTimer);
        };
    }, [id, removeTime, onRemove]);

    const handleOnRemove = (): void => {
        onRemove(id);
    };

    return (
        <Container className={`toast ${type} ${fade ? 'fade' : ''}`}>
            {icon}
            <Message>{message}</Message>
            <StyledIconButton
                title={t('modal.close')}
                type="button"
                onClick={handleOnRemove}
                aria-label={t('general.close')}
            >
                <CloseIcon />
            </StyledIconButton>
        </Container>
    );
}

export default ToastMessage;
