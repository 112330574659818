/* eslint-disable react/jsx-no-useless-fragment */
import { PATH } from 'constants/paths';
import useAuthorization from 'hooks/useAuthorization';
import { JSX, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EBusinessFeature } from 'types/business';

type AuthRouteProps = {
    children: React.ReactNode;
    feature: EBusinessFeature[];
};

function AuthRoute({ children, feature }: AuthRouteProps): JSX.Element {
    const [allowed, setAllowed] = useState(false);
    const navigate = useNavigate();
    const {
        checkAuthorizationFeature,
        checkAuthorizationFeatureArray,
        error: errorAuthorization,
        isLoading: isLoadingAuthorization,
    } = useAuthorization();

    useEffect(() => {
        if (!isLoadingAuthorization) {
            if (errorAuthorization) {
                if (errorAuthorization.temporary) {
                    navigate(PATH.TEMPORARY_ERROR_NO_MENU);
                } else {
                    navigate(PATH.CRITICAL_ERROR_NO_MENU);
                }
                return;
            }

            const isAllowed = checkAuthorizationFeatureArray(feature);
            if (!isAllowed) {
                navigate(PATH.NO_PERMISSION, { replace: true });
            } else {
                setAllowed(true);
            }
        }
    }, [
        feature,
        isLoadingAuthorization,
        checkAuthorizationFeature,
        navigate,
        setAllowed,
        errorAuthorization,
        checkAuthorizationFeatureArray,
    ]);

    if (allowed) {
        return <>{children}</>;
    }
    return <></>;
}

export default AuthRoute;
