import { JSX } from 'react';
import styled from 'styled-components';
import { font, spaceS, spaceXxs } from 'styles/variables';

export const KpiList = styled.ol`
    container: kpiList;
    container-type: inline-size;
`;

const ListItem = styled.li`
    line-height: ${font.lineHeight.m};
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
        margin-bottom: ${spaceXxs};
    }

    @container (max-width: 15rem) {
        flex-direction: column;
    }
`;
const Value = styled.span`
    font-family: ${font.semiBold};
    text-align: right;
    span {
        display: block;
        margin-bottom: ${spaceXxs};
    }
    @container (max-width: 240px) {
        text-align: left;
    }
`;

export const KpiListTotal = styled(KpiListItem)`
    &:not(:last-child) {
        margin-bottom: ${spaceS};
    }
    span {
        font-family: ${font.semiBold};
    }
`;

type KpiListTextProps = {
    title: string;
    value: string | number | string[];
    dataTestId?: string;
    className?: string;
};

function KpiListItem({ title, value, dataTestId, className }: KpiListTextProps): JSX.Element {
    const isArrayValue = Array.isArray(value);
    return (
        <ListItem data-testid={dataTestId} className={className}>
            <span>{title}</span>
            <Value>{isArrayValue ? value.map((val) => <span key={val}>{val}</span>) : value}</Value>
        </ListItem>
    );
}

export default KpiListItem;
