import { JSX } from 'react';
import styled from 'styled-components';
import { font, lighterBlue, primaryMerBlue } from 'styles/variables';

const Circle = styled.div`
    background: ${lighterBlue};
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: ${font.size.xxs};
    color: ${primaryMerBlue};
    display: flex;
    justify-content: center;
    align-items: center;
`;

function MenuNumberIndicator({ number = 0, dataTestId = '' }): JSX.Element {
    const text = number > 99 ? '99+' : number;
    return <Circle data-testid={dataTestId}>{text}</Circle>;
}

export default MenuNumberIndicator;
