import LanguageSelector from 'components/forms/LanguageSelector';
import MerLogo from 'components/visuals/MerLogo';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { headerBorderColor, headerHeight, headerZIndex, white } from 'styles/variables';

const merLogoSize = '4rem';

export const Container = styled.header`
    grid-area: header;
    background-color: ${white};
    display: grid;
    grid-template-columns: ${merLogoSize} minmax(0, 1fr) auto auto;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: ${headerZIndex};
    transition: box-shadow 0.67s ease-in-out;
    border-bottom: 1px solid ${headerBorderColor};

    &.pinned {
        box-shadow: 0 1px 1px rgba(32, 32, 32, 0.1);
    }
`;

export const LogoLink = styled(Link)`
    display: inline-block;
    margin: 0;
    height: ${headerHeight};
`;

function HeaderWithoutUserMenu(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container data-testid="headerWithoutUserMenu">
            <LogoLink to="/" title={t('general.homeButton')}>
                <MerLogo size={merLogoSize} />
            </LogoLink>
            <LanguageSelector />
        </Container>
    );
}

export default HeaderWithoutUserMenu;
