import StatusInfoPopup from 'components/info/StatusInfoPopup';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { ETrackingOrigin } from 'types/tracking';

type SubscriptionStatusPopUpProps = {
    gaTrackingOrigin: ETrackingOrigin;
};

function SubscriptionStatusPopUp({ gaTrackingOrigin }: SubscriptionStatusPopUpProps): JSX.Element {
    const { t } = useTranslation();

    const statusList = [
        {
            title: t('status.active'),
            text: t('subscription.statuses.active'),
        },
        {
            title: t('status.inactive'),
            text: t('subscription.statuses.inactive'),
        },
    ];

    return <StatusInfoPopup statusList={statusList} gaTrackingOrigin={gaTrackingOrigin} />;
}

export default SubscriptionStatusPopUp;
