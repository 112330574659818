import MenuIconNoItems from './MenuIconNoItems';
import MenuIconWithItems from './MenuIconWithItems';
import { MenuListType } from './NavigationMenu';
import { JSX } from 'react';
import { useLocation } from 'react-router-dom';

type CollapsedNavigationMenuProps = {
    item: MenuListType;
};

function CollapsedNavigationMenu({ item }: CollapsedNavigationMenuProps): JSX.Element {
    const { items, path } = item;
    const location = useLocation();
    const activeItem = items.find((menuItem) => location.pathname.includes(menuItem.path));
    return (
        <li className={`navigationMenu_${item.name}`} data-testid={`collapsedNavigationMenu_${item.name}`}>
            {items.length ? (
                <MenuIconWithItems isActive={!!activeItem} item={item} />
            ) : (
                <MenuIconNoItems isActive={location.pathname === path} item={item} />
            )}
        </li>
    );
}

export default CollapsedNavigationMenu;
