import { Divider } from './Divider';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import { JSX } from 'react';
import styled from 'styled-components';
import {
    borderRadiusM,
    font,
    panelBackgroundColorBlue,
    panelBackgroundColorGrey,
    primaryMerBlue,
    smallButtonHeight,
    spaceL,
    spaceM,
    spaceS,
} from 'styles/variables';

const Container = styled.section<{ $backgroundColor: string }>`
    background: ${({ $backgroundColor }) => $backgroundColor};
    padding: ${spaceL} ${spaceM} 4rem ${spaceM};
    border-radius: ${borderRadiusM};
`;
const Title = styled.section<{ $button?: React.ReactNode }>`
    display: grid;
    grid-template-columns: ${({ $button }) => ($button ? `1fr auto` : `1fr`)};
    gap: ${spaceS};
    align-items: center;
    min-height: ${smallButtonHeight};
    h2 {
        font-size: ${font.size.l};
        line-height: ${font.lineHeight.l};
    }
`;

const StyledDivider = styled(Divider)`
    margin-top: ${spaceS};
`;

const Description = styled.p`
    font-size: ${font.size.s};
    color: ${primaryMerBlue};
    margin: 0;
`;

type PanelProps = {
    title: string;
    description?: string;
    children: React.ReactNode;
    color?: 'grey' | 'blue';
    className?: string;
    isLoading?: boolean;
    button?: React.ReactNode;
    dataTestId?: string;
    showDivider?: boolean;
};
function SectionPanel({
    title,
    children,
    color = 'grey',
    description,
    className,
    isLoading = false,
    button,
    dataTestId,
    showDivider = true,
}: PanelProps): JSX.Element {
    const backgroundColor = color === 'blue' ? panelBackgroundColorBlue : panelBackgroundColorGrey;

    return (
        <Container $backgroundColor={backgroundColor} className={className} data-testid={dataTestId}>
            <Title $button={button}>
                <div>
                    <h2>{title}</h2>
                    {description ? <Description data-testid="sectionDescription">{description}</Description> : null}
                </div>
                {button}
            </Title>
            {showDivider && <StyledDivider />}
            {isLoading ? <LoadingIndicator /> : children}
        </Container>
    );
}

export default SectionPanel;
