import KpiContainer from 'components/kpi/KpiContainer';
import KpiListItem, { KpiList, KpiListTotal } from 'components/kpi/KpiListItem';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumedEnergyForType, EElectricCurrentType } from 'types/subscription';
import { getTotalKwh, getUsageByType } from 'utils/subscription/chart';

type TotalKwhPerTypeProps = {
    data?: ConsumedEnergyForType[];
};

function TotalKwhPerType({ data = [] }: TotalKwhPerTypeProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();
    return (
        <KpiContainer
            dataTestId="totalChargingKpi"
            title={t('subscription.statistics.kpi.totalCharging')}
            content={
                <KpiList>
                    <KpiListTotal title={t('general.total')} value={`${formatNumber(getTotalKwh(data))} kWh`} />
                    <KpiListItem
                        title={t(`subscription.statistics.kpi.${EElectricCurrentType.AC}`)}
                        value={`${formatNumber(getUsageByType(EElectricCurrentType.AC, data))} kWh`}
                    />
                    <KpiListItem
                        title={t(`subscription.statistics.kpi.${EElectricCurrentType.DC}`)}
                        value={`${formatNumber(getUsageByType(EElectricCurrentType.DC, data))} kWh`}
                    />
                    <KpiListItem
                        title={t(`subscription.statistics.kpi.${EElectricCurrentType.UFC}`)}
                        value={`${formatNumber(getUsageByType(EElectricCurrentType.UFC, data))} kWh`}
                    />
                </KpiList>
            }
        />
    );
}

export default TotalKwhPerType;
