import ApiError from 'classes/ApiError';
import Banner from 'components/banner/Banner';
import { LoadingIndicator } from 'components/indicators/LoadingIndicator';
import ErrorMessage from 'components/info/ErrorMessage';
import { JSX } from 'react';
import styled from 'styled-components';
import { font, maxWidthContent, readingWidth, spaceL, spaceS } from 'styles/variables';
import { EPageErrorType } from 'types';

const PageContainer = styled.div`
    max-width: ${maxWidthContent};
    container-type: inline-size;
    container-name: page-container;
`;

const HeaderSection = styled.section`
    max-width: ${readingWidth};
    margin-bottom: ${spaceL};
`;

const Description = styled.p`
    margin-top: ${spaceS};
`;

const Header = styled.h1`
    font-family: ${font.bold};
    font-size: ${font.size.xl};
    line-height: ${font.lineHeight.xl};
`;

type Props = {
    title: string;
    description?: string | React.ReactNode;
    children: React.ReactNode;
    isLoading?: boolean;
    errors?: ApiError[];
    dataTestId?: string;
    pageKey?: string;
};

export default function Page({
    title,
    description,
    children,
    isLoading = false,
    errors,
    dataTestId,
    pageKey,
}: Props): JSX.Element {
    let pageContent = children;
    if (errors && errors.length > 0) {
        pageContent = <ErrorMessage type={EPageErrorType.TEMPORARY} refresh />;
    }

    return (
        <PageContainer data-testid={dataTestId}>
            <Banner pageKey={pageKey} />
            <HeaderSection>
                <Header>{title}</Header>
                {description ? <Description data-testid="pageDescription">{description}</Description> : null}
            </HeaderSection>
            {isLoading ? <LoadingIndicator displayBorder={false} /> : pageContent}
        </PageContainer>
    );
}
