import ResetChargerIcon from 'assets/icons/reset_charger_icon.svg?react';
import Button from 'components/clickables/Button';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { darkerGrey, font, spaceL, spaceM } from 'styles/variables';
import { ButtonVariant } from 'types';

const ModalContainer = styled.div`
    padding: ${spaceM};
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${spaceM};
`;
const Header = styled.h1`
    font-size: ${font.size.l};
    line-height: ${font.lineHeight.l};
`;

const Description = styled.p`
    font-size: ${font.size.s};
    margin: ${spaceM} 0 ${spaceL};
    color: ${darkerGrey};
`;

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledButton = styled(Button)`
    min-width: 200px;
`;

type ResetChargerType = {
    onCancelClick?: () => void;
    onResetClick?: () => void;
};

function ResetChargerModal({ onCancelClick, onResetClick }: ResetChargerType): JSX.Element {
    const { t } = useTranslation();
    return (
        <ModalContainer data-testid="resetChargerModal">
            <IconWrapper>
                <ResetChargerIcon data-testid="resetChargerIcon" />
            </IconWrapper>
            <Header>{t('resetChargerModal.title')}</Header>
            <Description>{t('resetChargerModal.description')}</Description>
            <ButtonsWrapper>
                <StyledButton onClick={onCancelClick} dataTestId="cancelResetCharger" variant={ButtonVariant.SECONDARY}>
                    {t('resetChargerModal.buttons.cancel')}
                </StyledButton>
                <StyledButton onClick={onResetClick} dataTestId="resetCharger">
                    {t('resetChargerModal.buttons.reset')}
                </StyledButton>
            </ButtonsWrapper>
        </ModalContainer>
    );
}

export default ResetChargerModal;
