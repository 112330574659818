import { ETimeFrame } from 'components/subscriptions/statistics/TimeFrameFilter';
import { eachDayOfInterval, endOfISOWeek, format, getDaysInMonth, startOfISOWeek } from 'date-fns';
import {
    ActiveSubscription,
    ChartEntries,
    ConsumedEnergy,
    ConsumedEnergyForType,
    EElectricCurrentType,
    EUsageType,
    KpiDataItem,
} from 'types/subscription';
import { getDateLocale } from 'utils/dateLocalization';

export const getTotalKwh = (data: ConsumedEnergy[]): number => {
    return data.reduce((result, item) => result + item.kWh, 0);
};

export const getDateText = (date: Date, timeFrame: string): string => {
    if (timeFrame === ETimeFrame.WEEKLY) {
        return format(date, 'd MMM', { locale: getDateLocale() });
    }
    if (timeFrame === ETimeFrame.MONTHLY) {
        return format(date, 'd', { locale: getDateLocale() });
    }
    if (timeFrame === ETimeFrame.YEARLY) {
        return format(date, 'MMM', { locale: getDateLocale() });
    }
    return '';
};

export const getUsageByType = (
    type: EUsageType | EElectricCurrentType,
    data: ConsumedEnergyForType[] | ConsumedEnergy[],
): number => {
    const typeData = data.find((item) => item.name === type);
    return typeData?.kWh ?? 0;
};

type BarChartItem = {
    name: string;
    public: number;
    internal: number;
    home: number;
};

const getChartData = (data: ChartEntries[], timeFrame: string): BarChartItem[] => {
    return data.map((item) => {
        const barText = getDateText(new Date(item.year, item.month - 1, item.day ?? 1), timeFrame); // need to add 1 on day as yearly data does not provide day field
        const energyLocations = item.consumedEnergyForLocations;

        return {
            name: barText,
            public: getUsageByType(EUsageType.PUBLIC, energyLocations),
            internal: getUsageByType(EUsageType.INTERNAL, energyLocations),
            home: getUsageByType(EUsageType.HOME, energyLocations),
        };
    });
};

const generateWeeklyBarChartData = (data: ChartEntries[]): BarChartItem[] => {
    const firstDateFromArray = new Date(data[0].year, data[0].month - 1, data[0].day);
    const chartData = getChartData(data, ETimeFrame.WEEKLY);

    const range = eachDayOfInterval({
        start: startOfISOWeek(firstDateFromArray), // first day from calendar ISO week
        end: endOfISOWeek(firstDateFromArray), // last day from calendar ISO week
    });

    const formattedRange = range.map((value) => {
        const day = {
            name: getDateText(value, ETimeFrame.WEEKLY),
            public: 0,
            internal: 0,
            home: 0,
        };
        return chartData.find((existingday) => existingday.name === day.name) ?? day;
    });

    return formattedRange;
};

const generateMonthlyBarChartData = (data: ChartEntries[]): BarChartItem[] => {
    const firstRecord = data[0];
    const daysInMonth = getDaysInMonth(new Date(firstRecord.year, firstRecord.month - 1));
    const chartData = getChartData(data, ETimeFrame.MONTHLY);

    const fullMonth = Array(daysInMonth)
        .fill({
            name: '',
            public: 0,
            internal: 0,
            home: 0,
        })
        .map((item, index) => {
            const dayNumber = index + 1;
            const day = {
                ...item,
                name: dayNumber.toString(),
            };
            return chartData.find((existingday) => existingday.name === day.name) ?? day;
        });
    return fullMonth;
};

const generateYearlyBarChartData = (data: ChartEntries[]): BarChartItem[] => {
    const firstRecord = data[0];
    const chartData = getChartData(data, ETimeFrame.YEARLY);
    const fullYear = Array(12)
        .fill({
            name: '',
            public: 0,
            internal: 0,
            home: 0,
        })
        .map((item, index) => {
            const day = {
                ...item,
                name: getDateText(new Date(firstRecord.year, index), ETimeFrame.YEARLY),
            };
            return chartData.find((existingday) => existingday.name === day.name) ?? day;
        });

    return fullYear;
};

export const generateBarChartData = (data: ChartEntries[], timeFrame: string): BarChartItem[] => {
    let chartData: BarChartItem[] = [];
    if (!data.length) {
        return [];
    }

    switch (timeFrame) {
        case ETimeFrame.WEEKLY:
            chartData = generateWeeklyBarChartData(data);
            break;
        case ETimeFrame.MONTHLY:
            chartData = generateMonthlyBarChartData(data);
            break;
        case ETimeFrame.YEARLY:
            chartData = generateYearlyBarChartData(data);
            break;
        default:
            break;
    }

    return chartData;
};
export const generateActiveSubscriptions = (data: ActiveSubscription[]): KpiDataItem[] => {
    return data.map((activeSubscription) => ({ name: activeSubscription.type, value: activeSubscription.amount }));
};

export const getActiveSubscriptionsTotalNumber = (data: ActiveSubscription[]): number => {
    return data.reduce((result, item) => result + item.amount, 0);
};
