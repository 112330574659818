import QuestionMarkTooltipIcon from 'assets/icons/question_mark_32.svg?react';
import PopUp from 'components/info/PopUp';
import TooltipContentComponent from 'components/info/TooltipContent';
import KpiContainer from 'components/kpi/KpiContainer';
import { KpiList, KpiListTotal } from 'components/kpi/KpiListItem';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

type TotalDistanceProps = {
    data?: number;
};

function TotalDistance({ data = 0 }: TotalDistanceProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    return (
        <KpiContainer
            dataTestId="drivenKm"
            title={t('subscription.statistics.kpi.totalDistance')}
            content={
                <KpiList>
                    <KpiListTotal title={t('general.total')} value={`${formatNumber(data)} km`} />
                </KpiList>
            }
            tooltip={
                <PopUp
                    dataTestId="kilometerInfo"
                    content={
                        <TooltipContentComponent
                            headline={t('subscription.statistics.kpi.infoBoxKilometer.headline')}
                            text={t('subscription.statistics.kpi.infoBoxKilometer.text')}
                            icon={<QuestionMarkTooltipIcon />}
                        />
                    }
                />
            }
        />
    );
}

export default TotalDistance;
