import { ModalTitle } from 'components/elements/ModalTitle';
import DropdownInput from 'components/forms/DropdownInput';
import { ChangeEvent, JSX, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { spaceM, spaceS } from 'styles/variables';
import { ISubscriptionPlan } from 'types/product';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${spaceM} ${spaceM} ${spaceM};
    height: 70vh;
    gap: ${spaceS};
`;

const Description = styled.p`
    white-space: break-spaces;
    height: 100%;
    overflow: auto;
`;

export type SubscriptionTypesInfoProps = {
    subscriptionPlans: ISubscriptionPlan[];
};

function SubscriptionTypesInfo({ subscriptionPlans = [] }: SubscriptionTypesInfoProps): JSX.Element {
    const { t } = useTranslation();
    const [selectedPlan, setSelectedPlan] = useState(subscriptionPlans[0]);

    const handleOnChange = (e: ChangeEvent<HTMLSelectElement>): void => {
        const selected = subscriptionPlans.find((plan) => plan.id === e.target.value);
        if (selected) {
            setSelectedPlan(selected);
        }
    };

    return (
        <>
            <ModalTitle data-testid="subscriptionPlansTitle">{t('subscription.subscriptionPlans')}</ModalTitle>
            <Container>
                <DropdownInput
                    dataTestId="subscriptionPlanDropdown"
                    options={subscriptionPlans?.map((plan) => ({
                        value: plan.id,
                        text: plan.offer?.name || plan.name,
                    }))}
                    disabled={subscriptionPlans.length === 1}
                    name="subscriptionPlans"
                    hidePlaceholderOption
                    onChange={handleOnChange}
                />
                <Description data-testid="subscriptionPlanDescription">{selectedPlan.description}</Description>
            </Container>
        </>
    );
}

export default SubscriptionTypesInfo;
