import CollapsedNavigationMenu from './CollapsedNavigationMenu';
import ExpandedNavigationMenu from './ExpandedNavigationMenu';
import ChargerIcon from 'assets/icons/charger.svg?react';
import Chevron from 'assets/icons/chevron.svg?react';
import DashboardIcon from 'assets/icons/dashboard.svg?react';
import InvoiceIcon from 'assets/icons/invoice.svg?react';
import AccountIcon from 'assets/icons/my_account.svg?react';
import SubscriptionIcon from 'assets/icons/subscriptions.svg?react';
import Button from 'components/clickables/Button';
import FullPageLoadingIndicator from 'components/indicators/FullPageLoadingIndicator';
import Tooltip from 'components/info/Tooltip';
import { PATH } from 'constants/paths';
import useAuthorization from 'hooks/useAuthorization';
import useLocalState from 'hooks/useLocalState';
import useShowFeatureFlag from 'hooks/useShowFeatureFlag';
import { JSX, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    collapsedMenuWidth,
    expandedMenuWidth,
    grey,
    headerHeight,
    lighterGrey,
    transitionDefault,
} from 'styles/variables';
import { FEATURE_LIST } from 'types';
import { EBusinessFeature } from 'types/business';
import { ETrackingAction, ETrackingEvent, ETrackingType } from 'types/tracking';
import { maintainScrollPosition } from 'utils/maintainScrollPosition';

const Container = styled.nav`
    grid-area: menu;
    height: 100%;
    position: relative;
`;

const MenuList = styled.ul<{ $isExpanded: boolean }>`
    top: ${headerHeight};
    width: ${({ $isExpanded }) => ($isExpanded ? expandedMenuWidth : collapsedMenuWidth)};
    transition: width ${transitionDefault};
    & > li {
        border-bottom: 1px solid ${grey};
    }
`;

const ScrollableWrapper = styled.div`
    background-color: ${lighterGrey};
    position: fixed;
    top: ${headerHeight};
    bottom: 0;
    &.expanded {
        overflow: hidden auto;
        overscroll-behavior: contain;
    }
`;

const expandedPosition = `calc(${expandedMenuWidth} - 0.75rem)`;
const collapsedPosition = `calc(${collapsedMenuWidth} - 0.75rem)`;

const ArrowButton = styled(Button)<{ $menuExpanded?: boolean; $visible?: boolean }>`
    opacity: ${({ $visible }) => ($visible ? '1' : '0')};
    width: 1.5rem;
    height: 1.5rem;
    min-width: auto;
    padding: 0;
    position: fixed;
    border-radius: 100%;
    top: calc(${headerHeight} + 0.625rem);
    left: ${({ $menuExpanded }) => ($menuExpanded ? expandedPosition : collapsedPosition)};
    transform: ${({ $menuExpanded }) => ($menuExpanded ? 'scaleX(-1)' : 'scaleX(1)')};
    transition:
        left ${transitionDefault},
        opacity ${transitionDefault};
    z-index: 1;
    svg {
        transform: rotate(-90deg);
        height: 0.375rem;
    }
`;

export type ItemType = {
    title: string;
    name: string;
    path: string;
    visible: boolean;
    numberIndicatorValue?: number;
    showNumberIndicator?: boolean;
};

export type MenuListType = {
    title: string;
    name: string;
    icon: JSX.Element;
    visible: boolean;
    path: PATH;
    showDot?: boolean;
    items: ItemType[];
};

function NavigationMenu(): JSX.Element {
    const [hover, setHover] = useState(false);
    const { checkAuthorizationFeature, isLoading: isLoadingAuthorization } = useAuthorization();
    const { t } = useTranslation();

    const {
        actions: { setSideMenuCollapsed, setSideMenuExpanded },
        state: { sideMenuExpanded },
    } = useLocalState();

    const visibleInvoices = checkAuthorizationFeature(EBusinessFeature.INVOICES_MANAGEMENT_VIEW);

    const visibleSubscriptionEdit = checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_EDIT);
    const visibleSubscriptionView = checkAuthorizationFeature(EBusinessFeature.SUBSCRIPTION_MANAGEMENT_VIEW);

    const visibleFacilityStatisticsEdit = checkAuthorizationFeature(EBusinessFeature.FACILITY_MANAGEMENT_EDIT);
    const visibleFacilityStatisticsView = checkAuthorizationFeature(EBusinessFeature.FACILITY_MANAGEMENT_VIEW);

    const { showFeature } = useShowFeatureFlag();

    const startpageNavButton = {
        title: t('menu.startpage.title'),
        name: 'startpage',
        icon: <DashboardIcon />,
        visible: true,
        path: PATH.STARTPAGE,
        items: [],
    };

    const subscriptionNavButton = {
        title: t('menu.subscription.title'),
        name: 'subscription',
        icon: <SubscriptionIcon />,
        path: '' as PATH,
        visible: visibleSubscriptionEdit || visibleSubscriptionView,
        items: [
            {
                title: t('menu.subscription.create'),
                name: 'subscription_create',
                path: PATH.SUBSCRIPTION_CREATE,
                visible: visibleSubscriptionEdit,
            },
            {
                title: t('menu.subscription.import'),
                name: 'subscription_import',
                path: PATH.SUBSCRIPTION_IMPORT,
                visible: visibleSubscriptionEdit,
            },
            {
                title: t('menu.subscription.overview'),
                name: 'subscription_overview',
                path: PATH.SUBSCRIPTION_OVERVIEW,
                visible: visibleSubscriptionEdit || visibleSubscriptionView,
            },
            {
                title: t('menu.subscription.orders'),
                name: 'subscription_orders',
                path: PATH.SUBSCRIPTION_ORDERS,
                visible: visibleSubscriptionEdit || visibleSubscriptionView,
            },
            {
                title: t('menu.subscription.statistics'),
                name: 'subscription_statistics',
                path: PATH.SUBSCRIPTION_STATISTICS,
                visible: visibleSubscriptionEdit || visibleSubscriptionView,
            },
            {
                title: t('menu.subscription.chargingHistory'),
                name: 'subscription_chargingHistory',
                path: PATH.SUBSCRIPTION_CHARGING_HISTORY,
                visible: visibleSubscriptionEdit || visibleSubscriptionView,
            },
        ],
    };

    const myAccountNavButton = {
        title: t('menu.myAccount.title'),
        name: 'myAccount',
        icon: <AccountIcon />,
        visible: true,
        path: PATH.MY_ACCOUNT,
        items: [],
    };

    const facilityChargersNavButton = {
        title: t('menu.facility.title'),
        name: 'facility',
        icon: <ChargerIcon />,
        path: '' as PATH,
        visible: visibleFacilityStatisticsEdit || visibleFacilityStatisticsView,
        items: [
            {
                title: t('menu.facility.chargers'),
                name: 'facility_chargers',
                path: PATH.FACILITY_CHARGERS,
                visible: showFeature(FEATURE_LIST.FACILITY_CHARGERS),
            },
            {
                title: t('menu.facility.statistics'),
                name: 'facility_statistics',
                path: PATH.FACILITY_STATISTICS,
                visible: true,
            },
        ],
    };

    const invoicesNavButton = {
        title: t('menu.invoices.title'),
        name: 'invoices',
        icon: <InvoiceIcon />,
        path: PATH.INVOICES,
        visible: visibleInvoices && showFeature(FEATURE_LIST.INVOICES),
        items: [],
    };

    const menuList: MenuListType[] = [
        startpageNavButton,
        subscriptionNavButton,
        facilityChargersNavButton,
        invoicesNavButton,
        myAccountNavButton,
    ];

    const isLoading = isLoadingAuthorization;

    if (document.getElementById('menuScrollableWrapper') !== null) {
        document.getElementById('menuScrollableWrapper')!.addEventListener('scroll', () => {
            const position = document.getElementById('menuScrollableWrapper')?.scrollTop as number;
            sessionStorage.setItem('scrollPosition', position.toString());
        });
    }

    useEffect(() => {
        maintainScrollPosition();
    });

    window.onbeforeunload = () => {
        sessionStorage.removeItem('scrollPosition');
    };

    return !isLoading ? (
        <Container
            data-testid={`navigationMenu_${sideMenuExpanded ? 'expanded' : 'collapsed'}`}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
        >
            <Tooltip
                text={sideMenuExpanded ? t('general.hide') : t('general.show')}
                fixedPosition
                offsetValue={10}
                placement="right"
            >
                <ArrowButton
                    dataTestId="navigationToggleButton"
                    $visible={hover}
                    $menuExpanded={sideMenuExpanded}
                    onClick={() => {
                        ReactGA.event(ETrackingEvent.NAVIGATION_MENU, {
                            type: ETrackingType.NAVIGATION_TOGGLE_BUTTON,
                            action: sideMenuExpanded ? ETrackingAction.COLLAPSE : ETrackingAction.EXPAND,
                        });
                        if (sideMenuExpanded) {
                            setSideMenuCollapsed();
                        } else {
                            setSideMenuExpanded();
                        }
                    }}
                >
                    <Chevron />
                </ArrowButton>
            </Tooltip>
            <ScrollableWrapper id="menuScrollableWrapper" className={sideMenuExpanded ? 'expanded' : ''}>
                <MenuList $isExpanded={sideMenuExpanded}>
                    {menuList
                        .filter((item) => item.visible)
                        .map((item) =>
                            sideMenuExpanded ? (
                                <ExpandedNavigationMenu key={item.title} item={item} />
                            ) : (
                                <CollapsedNavigationMenu key={item.title} item={item} />
                            ),
                        )}
                </MenuList>
            </ScrollableWrapper>
        </Container>
    ) : (
        <FullPageLoadingIndicator />
    );
}

export default NavigationMenu;
