export const PAGE_SIZE = 50;

// FORM FILEDS
export const MAX_CHARACTER_LENGTH = 50;
export const MAX_LENGTH_NAME = 25;
export const MAX_LENGTH_EMAIL = 50;
export const FREE_INPUT_FIELDS_LENGTH = 255;
export const MAX_DATE_RANGE = 366;
export const EMAIL_REGEXP = /^[^@]+@[^@]+\.[^@]+$/;
export const GLN_REGEXP = /^\d{13}$/; // Global Location Number

export const GENERAL_BFF_PREFIX = 'cor-mer-hub-bff';
export const RESET_BFF_ENDPOINT_PREFIX = 'cor-mer-hub-bff-reset-charger-api';

export const TwentyFourHoursInMs = 86400000;
export const REACT_QUERY_ONE_CALL_OPTIONS = {
    staleTime: TwentyFourHoursInMs,
    cacheTime: TwentyFourHoursInMs,
    refetchOnMount: true,
};

export const MILISECONDS_BEFORE_EXPIRATION = 1 * 60 * 1000; // 1 minute
