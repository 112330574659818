import KpiContainer from 'components/kpi/KpiContainer';
import KpiListItem, { KpiList, KpiListTotal } from 'components/kpi/KpiListItem';
import { useNumber } from 'hooks/useNumber';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { ConsumedEnergy } from 'types/subscription';
import { getTotalKwh } from 'utils/subscription/chart';

type PartnerRoamingKpiProps = {
    data?: ConsumedEnergy[];
};

function PartnerRoamingKpi({ data = [] }: PartnerRoamingKpiProps): JSX.Element {
    const { t } = useTranslation();
    const { formatNumber } = useNumber();

    const totalKwh = getTotalKwh(data).toFixed(2);

    return (
        <KpiContainer
            title={t('subscription.statistics.kpi.partnerRoaming')}
            content={
                <KpiList data-testid="partnerRoamingList">
                    <KpiListTotal
                        dataTestId="partnerRoamingTotal"
                        title="Total"
                        value={`${formatNumber(Number(totalKwh))} kWh`}
                    />

                    {data.map((item) => {
                        return (
                            <KpiListItem
                                key={item.name}
                                title={item.name}
                                value={`${formatNumber(Number(item.kWh))} kWh`}
                            />
                        );
                    })}
                </KpiList>
            }
            dataTestId="partnerRoamingKpi"
        />
    );
}

export default PartnerRoamingKpi;
