import {
    autoUpdate,
    FloatingPortal,
    offset,
    Placement,
    shift,
    useClientPoint,
    useDismiss,
    useFloating,
    useFocus,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { JSX, useState } from 'react';
import styled from 'styled-components';
import { borderRadiusS, darkerBlue, font, white } from 'styles/variables';

const StyledTooltip = styled.div`
    background: ${darkerBlue};
    font-family: ${font.body};
    font-size: ${font.size.xs};
    font-family: ${font.semiBold};
    color: ${white};
    border-radius: ${borderRadiusS};
    padding: 6px;
    z-index: 999;
`;
const Container = styled.div`
    cursor: pointer;
    display: inherit;
`;

type TooltipProps = {
    text: string | React.ReactNode;
    children: React.ReactNode;
    fixedPosition?: boolean;
    placement?: Placement;
    offsetValue?: number;
};

function Tooltip({ text, children, fixedPosition, placement = 'bottom', offsetValue = 20 }: TooltipProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [offset(offsetValue), shift()],
    });

    const hover = useHover(context, { move: false, delay: 100 });
    const focus = useFocus(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });
    const clientPoint = useClientPoint(context, { enabled: !fixedPosition });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role, clientPoint]);

    return (
        <>
            <Container ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </Container>
            {isOpen && (
                <FloatingPortal>
                    <StyledTooltip
                        className="Tooltip"
                        ref={refs.setFloating}
                        style={floatingStyles}
                        {...getFloatingProps()}
                    >
                        {text}
                    </StyledTooltip>
                </FloatingPortal>
            )}
        </>
    );
}

export default Tooltip;
