import { JSX } from 'react';
import styled from 'styled-components';
import { borderRadiusM, font, panelBackgroundColorBlue, spaceM, spaceS, spaceXl, spaceXs } from 'styles/variables';

const Kpi = styled.article`
    background: ${panelBackgroundColorBlue};
    display: flex;
    flex-direction: column;
    gap: ${spaceS};
    padding: 1.25rem;
    padding-top: ${spaceM};
    position: relative;
    border-radius: ${borderRadiusM};
`;

const Header = styled.section<{ $infoButton: boolean }>`
    margin-right: ${({ $infoButton }) => ($infoButton ? `${spaceXl}` : 0)};
    display: grid;
    grid-template-columns: 1fr;
`;
const Title = styled.h2`
    font-size: ${font.size.l};
`;

const Subtitle = styled.p`
    margin: 0;
`;

const InfoButton = styled.div`
    position: absolute;
    right: ${spaceXs};
    top: ${spaceXs};
`;

type KpiContainerProps = {
    title: string | React.ReactElement;
    subtitle?: string;
    tooltip?: React.ReactNode;
    content: React.ReactNode | React.ReactNode[];
    dataTestId?: string;
};
function KpiContainer({ title, subtitle, content, dataTestId, tooltip }: KpiContainerProps): JSX.Element {
    const showInfoButton = !!tooltip;
    return (
        <Kpi data-testid={dataTestId}>
            {showInfoButton && <InfoButton>{tooltip}</InfoButton>}
            <Header $infoButton={showInfoButton}>
                <Title>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Header>
            {content}
        </Kpi>
    );
}

export default KpiContainer;
