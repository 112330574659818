import useAuthentication from './useAuthentication';
import { remoteConfig } from 'firebase/firebaseConfig';
import { getBoolean, getString } from 'firebase/remote-config';
import { FEATURE_LIST } from 'types';

type UsersAllowed = {
    qa: string;
    users: string[];
};

type useShowFeatureFlagReturnType = {
    showFeature: (feature: FEATURE_LIST) => boolean;
};

const useShowFeatureFlag = (): useShowFeatureFlagReturnType => {
    const { getAccountContactId } = useAuthentication();
    const config = remoteConfig();
    const contactId = getAccountContactId();

    const superUserFeatureFlag = getString(config, FEATURE_LIST.SHOW_FEATURE_FOR_QA);

    const allowedUsersToView = superUserFeatureFlag ? (JSON.parse(superUserFeatureFlag) as UsersAllowed) : undefined;

    const shouldUserSeeFeature =
        allowedUsersToView?.qa.includes(contactId) || allowedUsersToView?.users.includes(contactId);

    function showFeature(feature: FEATURE_LIST): boolean {
        return shouldUserSeeFeature || getBoolean(config, feature);
    }

    return { showFeature };
};

export default useShowFeatureFlag;
