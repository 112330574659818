import German from './locales/de_DE/translation.json';
import English from './locales/en_GB/translation.json';
import Master from './locales/master/translation.json';
import Norwegian from './locales/no_NO/translation.json';
import Swedish from './locales/sv_SE/translation.json';
import { ELocale } from './types';
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: false;
    }
}

const resources = {
    [ELocale.SV]: {
        translation: Swedish,
    },
    [ELocale.EN]: {
        translation: English,
    },
    [ELocale.NO]: {
        translation: Norwegian,
    },
    [ELocale.DE]: {
        translation: German,
    },
    master: {
        translation: Master,
    },
};

export function normalizeLanguageCode(language: string): string {
    // Split the language code at the hyphen
    const parts = language.split('-');
    // Return the first part (language)
    return parts[0];
}

export function mapLanguageToLocale(language: string): ELocale {
    switch (language) {
        case 'sv':
            return ELocale.SV;
        case 'de':
            return ELocale.DE;
        case 'no':
            return ELocale.NO;
        default:
            return ELocale.EN;
    }
}

const normalizedLanguage = normalizeLanguageCode(navigator.language);

i18n.use(detector)
    .use(initReactI18next)
    .init({
        detection: {
            lookupLocalStorage: 'locale',
        },
        resources,
        fallbackLng: [ELocale.EN, 'master'],
        keySeparator: '.',
        interpolation: { escapeValue: false },
        returnNull: false,
        lng: localStorage.getItem('locale') ?? mapLanguageToLocale(normalizedLanguage),
    });
export default i18n;
