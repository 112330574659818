import useSignalR from './useSignalR';
import { useQueries } from '@tanstack/react-query';
import ApiError from 'classes/ApiError';
import { GENERAL_BFF_PREFIX } from 'constants/general';
import useApi from 'hooks/useApi';
import { useCallback, useEffect, useRef } from 'react';
import {
    EConnectorStatus,
    IConnectorStatus,
    IConnectorStatusNotification,
    IConnectorStatusNotificationData,
    IProperty,
} from 'types/facility';
import { batchArray, getSitesFromPropertyList } from 'utils/connectorStatus';
import { mapStatuses } from 'utils/facility';

export type Connector = {
    evseId: string;
    status: string;
};

export type UseConnectorStatusResponse = {
    connectors?: Connector[];
    isLoading: boolean;
    error?: ApiError;
};

export type ConnectorStatusResult = {
    connectors?: Connector[];
};

type UseConnectorStatusBodyProps = {
    siteIds: string[];
};

const useConnectorStatus = (
    properties: IProperty[],
    updateConnectorStatus: (data: IConnectorStatus) => void,
): UseConnectorStatusResponse => {
    const { post } = useApi<ConnectorStatusResult, UseConnectorStatusBodyProps>();
    const url = `${GENERAL_BFF_PREFIX}/v1/connector-status`;

    const sitesBatches = batchArray(getSitesFromPropertyList(properties), 1);

    const fetcher: (siteIds: string[]) => Promise<ConnectorStatusResult> = (siteIds) =>
        post(url, {
            siteIds,
        });

    const results = useQueries({
        queries: sitesBatches.map((sitesBatch) => ({
            queryKey: [url, sitesBatch.join(',')],
            queryFn: () => fetcher(sitesBatch),
            staleTime: Infinity,
        })),
    });

    const isLoading = results.some((result) => result.isFetching);

    const processedUpdatesRef = useRef(new Set<string>());

    const { joinGroup, connection } = useSignalR<string, IConnectorStatusNotification>({
        listenerName: 'newStatus',
        onListener: useCallback(
            (payload) => {
                if (payload) {
                    const parsedData: IConnectorStatusNotificationData = JSON.parse(payload.Data);
                    updateConnectorStatus({
                        evseId: parsedData.EvseIdentityKey,
                        status: mapStatuses(parsedData.SocketStatus),
                    });
                }
            },
            [updateConnectorStatus],
        ),
    });

    useEffect(() => {
        results.forEach((query, index) => {
            if (
                query.isSuccess &&
                query.data.connectors &&
                connection &&
                !processedUpdatesRef.current.has(sitesBatches[index].toString())
            ) {
                processedUpdatesRef.current.add(sitesBatches[index].toString());

                const evseIds: string[] = [];

                query.data.connectors.forEach((connector: Connector) => {
                    if (!evseIds.includes(connector.evseId)) {
                        evseIds.push(connector.evseId);
                        updateConnectorStatus({
                            evseId: connector.evseId,
                            status: connector.status as EConnectorStatus,
                        });
                    }
                });

                if (evseIds.length) {
                    joinGroup('JoinB2BGroup', evseIds.join(','));
                }
            }
        });
    }, [results, sitesBatches, joinGroup, connection, updateConnectorStatus]);

    return {
        isLoading,
    };
};

export default useConnectorStatus;
