import { INITIAL_GLOBAL_STATE } from 'constants/initialData';
import useAuthentication from 'hooks/useAuthentication';
import { createStore } from 'little-state-machine';
import { JSX, useEffect, useState } from 'react';

function StateMachineProviderWrapper({ children }: { children: React.ReactNode }): JSX.Element | null {
    const { getAccountContactId } = useAuthentication();

    const contactId = getAccountContactId();
    const [storeCreated, setStoreCreated] = useState<boolean>(false);

    useEffect(() => {
        const name = `mer_${contactId}`;
        createStore(
            {
                ...INITIAL_GLOBAL_STATE,
            },
            {
                name,
                middleWares: [],
                storageType: localStorage,
            },
        );
        setStoreCreated(true);
    }, [contactId, storeCreated]);

    if (storeCreated) {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        return <>{children}</>;
    }
    return null;
}

export default StateMachineProviderWrapper;
