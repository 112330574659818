import Header from 'components/layout/Header';
import NavigationMenu from 'components/navigation/NavigationMenu';
import useLocalState from 'hooks/useLocalState';
import { JSX } from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { collapsedMenuWidth, expandedMenuWidth, headerHeight, spaceL, transitionDefault } from 'styles/variables';

const Container = styled.div<{ $hasCollapsedMenu: boolean }>`
    min-height: 100vh;
    display: grid;
    grid-template-rows: ${headerHeight} 1fr;
    grid-template-columns: ${({ $hasCollapsedMenu }) =>
        $hasCollapsedMenu ? `${collapsedMenuWidth} 1fr` : `${expandedMenuWidth} 1fr`};
    grid-template-areas:
        'header header'
        'menu main';
    transition: all ${transitionDefault};
`;

const ContentContainer = styled.main`
    grid-area: main;
    padding: ${spaceL} 3.5rem;
    overflow: hidden;
    overscroll-behavior: contain;
`;

function PageLayout(props: RouteProps): JSX.Element {
    const { children } = props;

    const {
        state: { sideMenuExpanded },
    } = useLocalState();

    return (
        <Container $hasCollapsedMenu={!sideMenuExpanded}>
            <Header />
            <ContentContainer>{children}</ContentContainer>
            <NavigationMenu />
        </Container>
    );
}

export default PageLayout;
