import Cross from 'assets/illustrations/cross.svg?react';
import Button from 'components/clickables/Button';
import { useModal } from 'contexts/Modal';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { font, spaceL, spaceM, spaceXxl } from 'styles/variables';
import { ButtonVariant } from 'types';

const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    padding: ${spaceXxl} 0 ${spaceL} 0;
`;

const ButtonsContainer = styled.div`
    display: flex;
    padding: ${spaceM};
    gap: ${spaceM};
    button {
        flex: 1;
    }
`;

const TextHeader = styled.h1`
    font-size: ${font.size.l};
    margin: 0 ${spaceM};
    line-height: ${font.lineHeight.xl};
`;

export type RejectSubscriptionModalProps = {
    header: string;
    deleteOrder: () => void;
    children?: React.ReactNode;
};

function RejectSubscriptionModal({ header, deleteOrder, children }: RejectSubscriptionModalProps): JSX.Element {
    const { t } = useTranslation();
    const handleModal = useModal();

    const cancelOrder = (): void => {
        deleteOrder();
        handleModal.close();
    };

    return (
        <>
            <ModalHeader>
                <Cross />
            </ModalHeader>
            <TextHeader>{header}</TextHeader>
            {children}
            <ButtonsContainer>
                <Button
                    dataTestId="rejectSubscriptionModalCancel"
                    onClick={() => handleModal.close()}
                    variant={ButtonVariant.SECONDARY}
                >
                    {t('form.button.cancel')}
                </Button>
                <Button
                    dataTestId="rejectSubscriptionModalDelete"
                    onClick={cancelOrder}
                    variant={ButtonVariant.PRIMARY}
                >
                    {t('form.button.delete')}
                </Button>
            </ButtonsContainer>
        </>
    );
}

export default RejectSubscriptionModal;
