import useToken from './useToken';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { REACT_QUERY_ONE_CALL_OPTIONS } from 'constants/general';
import { useAppInsights } from 'contexts/AppInsightsContext';
import { BuType, UseBUContentResult } from 'types/businessUnit';

const emptyBUContent: BuType = {
    faqLink: '',
    privacyPolicyLink: '',
    termsOfSalesLink: '',
    customerService: {
        email: '',
        phoneNumer: '',
        openingHours: [
            {
                from: '',
                to: '',
                dayFrom: '',
                dayTo: '',
            },
        ],
    },
    availableLanguages: [],
};

const codeToCountryMapping: { [key: string]: string } = {
    '152': 'SE',
    '203': 'DE',
    '252': 'UK',
    '102': 'NO',
    '304': 'AT',
};

const useBUContent = (): UseBUContentResult => {
    const { businessUnitCode } = useToken();
    const { appInsights } = useAppInsights();

    const buFile = `/bu_${codeToCountryMapping[businessUnitCode]}.json`;

    const fetcher = async (): Promise<BuType | void> => {
        const result = await axios
            .get<BuType>(buFile)
            .then((resp) => resp.data)
            .catch((error) => {
                if (appInsights) {
                    appInsights.trackException({
                        exception: error,
                        severityLevel: 3,
                    });
                }
            });

        return result;
    };

    const { data: buContent } = useQuery<BuType | void>({
        queryKey: [buFile],
        queryFn: fetcher,
        ...REACT_QUERY_ONE_CALL_OPTIONS,
        enabled: !!businessUnitCode,
    });

    return {
        buContent: buContent ?? emptyBUContent,
    };
};

export default useBUContent;
